import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, CoherencePanel, CoherencePanelSize, panelPadding } from "@coherence-design-system/controls";
import { Checkbox, DatePicker, DefaultButton, DetailsListLayoutMode, Dropdown, FontIcon, ICommandBarItemProps, IDropdownOption, Label, Link, Pivot, PivotItem, PrimaryButton, Stack, TextField, TooltipHost, Callout, styled, ActivityItem, IActivityItemProps, mergeStyleSets, IStackStyles, IStackProps, IconButton, TagPicker, IBasePickerSuggestionsProps, ITag } from "@fluentui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { DOMEApiHelper, convertToCsv, isEmpty, onFormatDate } from "../../utils/DOMEApiHelper";
import { GetUserRole } from "../../utils/GetUserRole"
import ToastMessage from "../../shared/ToastMessage/ToastMessage";
import UtilDta from "../../utils/UtilCommon.json";
import { NotificationPivotOptions } from "../../shared/AppHeader/AppHeader.utils";
import CustomPagination from "../../shared/CutomPagination/CustomPagination";
import CustomGridControls from "../../shared/CustomGridControls/CustomGridControls";
import { SearchableDropdown } from "../../shared/CustomSearchDropdown/CustomSearchDropdown";
import { RMApiHelper } from "../../utils/RMApiHelper";
import { Icon } from '@fluentui/react/lib/Icon';
import { PrimaryContactPicker } from "../../shared/PrimaryContactPicker/PrimaryContactPicker";
import { redirect } from "react-router";
import NonManagedDiscussions from "../NonManaged/NonManagedDiscussions/NonManagedDiscussions";
import Accordion from "../../shared/Accordion/Accordion";
import { CustomRTEPlugin } from "../../shared/CustomRTEPlugin/CustomRTEPlugin";
import type { EditorPlugin } from 'roosterjs';
import { RichTextEditor } from "@coherence-design-system/rich-text-editor";

const FinanceDataAudit: React.FunctionComponent = () => {
    const [userRole, setUserRole] = useState(GetUserRole());
    const [isLoaded, setIsLoaded] = useState(false);
    const [LoadText, setLoadText] = useState<string>("");
    const [auditDataMonitoringIssueData, setAuditDataMonitoringIssueData] = useState<any>([]);
    const [FuncScenario, setFuncScenario] = useState<any>([]);
    const [editColumnsDefaultValues, seteditColumnsDefaultValues] = useState<any>();
    const [showEditGridPanel, setshowEditGridPanel] = useState<boolean>(false);
    const [showICMGridPanel, setsshowICMGridPanel] = useState<boolean>(false);																		  
    const [modalOpen, setmodalOpen] = useState<boolean>(false);
    const [refColumnsDefaultValues, setRefColumnsDefaultValues] = useState<any>(null);
    const [Resolution, setResolution] = useState<any>([]);
    const [ActionRequired, setActionRequired] = useState<any>([]);
    const [DataMismatchAttributesTitle, setDataMismatchAttributesTitle] = useState<any>();
    const [ResolutionErrMsg, setResolutionErrMsg] = useState<string>("");
    const [FuctionalSceErrMsg, setFuctionalSceErrMsg] = useState<string>("");
    const [InProgressStatus, setInProgressStatus] = useState(false);
    const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
    const [FunctionalScenariosList, setFunctionalScenariosList] = useState<any>([]); 
    const MyIcon = () => <Icon iconName="Info" />;
    const [onSaveDataValid, setOnSaveDataValid] = React.useState<boolean>(false);
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
    const [assignToErr, setAssignToErr] = React.useState<boolean>(false);
    const [AssignedToRequired, setAssignedToRequired] = React.useState<boolean>(false);
    const [icmTeamsList, setICMTeamsList] = useState<any>([]);
    const [icmopen, seticmopen] = useState<boolean>(false);
    const [icmDetails, setICMDetails] = useState<any>({
        "ServiceName": "",
        "Title": "",
        "Team": "",
        "Summary":   "",
        "Discussion":""
    });    													  
						
						
    const [AssignedTo, setAssignedTo] = useState<any>([]);

    //toaster code Start
    const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [toastTitle, setToastTitle] = useState<string>("");
    const [showToast, setShowToast] = React.useState<boolean>(false);
    //toaster code end 

    const handleValueChange = (newValue: boolean | ((prevState: boolean) => boolean)) => {
        setshowEditGridPanel(newValue);
    };

    let IdVal: number;

    const onEditPanelChange = (item: any,icmResponse?:number): void => {
        for (let key in editColumnsDefaultValues) {
            if (key === "Id") {
                IdVal = editColumnsDefaultValues[key];
            }
        }
        SaveAPI(IdVal,icmResponse);
    };
    // eslint-disable-next-line
    const [editRowColumns, seteditRowColumns] = useState<any>(
        [
            { "isEditable": true, "type": "ReadOnly", "label": "Data Type", "key": "DataType" },
            { "isEditable": true, "type": "ReadOnly", "label": "Data Value", "key": "DataValue" },
            { "isEditable": true, "type": "Recommendation", "label": "ML Recommendation", "key": "MLRecommendation" },
            { "isEditable": true, "type": "Contact", "label": "AssignedTo", "key": "AssignedTo" },
            { "isEditable": true, "type": "DropDown", "label": "Resolution", "key": "Resolution" }, 
            { "isEditable": true, "type": "Date", "label": "Target Completion Date", "key": "TargetCompletionDate" },
            { "isEditable": true, "type": "ReadOnly", "label": "Notes", "key": "Notes" }     
        ]
    );

    // eslint-disable-next-line 
    const hideModal = () => {
        setmodalOpen(false);
    };

    const openModal = (item: any) => {
        getPopUpdata(item);
    };

    const actionInProgress = UtilDta.filter((data: any) => data.id.includes("InProgress"));
    const ActionInProgressKeyId = ActionRequired.filter((data: any) => data.text.includes(actionInProgress[0].key));
    const getColumns = (): CoherenceDataGridColumn<any>[] => {

        var advancedColumns: CoherenceDataGridColumn<any>[] = [
            {
                key: 'selectAll',
                name: 'Select All',
                fieldName: 'SelectAll',
                type: 'string',
                isResizable: false,
                minColumnWidth: 20,
                maxWidth: 20,
                ariaLabel: 'Select All',
                data: [],
                onRender: (item: any) => {
                    return <Checkbox ariaLabel="Select row" onChange={(event) => handleSelectionCheckbox(event, item)} disabled={item.IsLocked || item.ActionRequired === ActionInProgressKeyId[0].key || item.Resolution === "System Resolved"} checked={item.isSelected} />;
                },
                onRenderHeader: (item: any) => {
                    return <Checkbox ariaLabel="Select all rows" styles={{ root: { marginTop: '12px', marginBottom: '4px' } }} onChange={(event) => handleSelectAllCheckbox(event, item)} checked={isSelectedAll} />;
                },
            },
            {
                key: 'Actions',
                name: 'Edit',
                type: 'actions',
                menuActions: [],
                isResizable: false,
                minColumnWidth: 50,
                maxWidth: 50,
                data: [],
                visibleActions: [
                    {
                        onClick: (item) => {
                            
                            fetchFunctionalScenarioByAffectedDataTypeId(item.DataType, item.AffectedDataTypeId)
                            setBulkEditButtonClicked(false);
                            setAssignToErr(false);
                            setIsDisabledSaveButton(true);
                            setAssignedToRequired(true);
                            let value:any =[];
                            if(!isEmpty(item.AssignedTo) && !isEmpty(item.AssignedToUserName)){
                                value=[{ "text": item.AssignedToUserName, "objectId": item.AssignedTo }];
                            }
                            else if(!isEmpty(item.AssignedTo) && isEmpty(item.AssignedToUserName)){ 
                                value=[{ "text": item.AssignedTo, "objectId": item.AssignedTo }];
                            }
                            setAssignedTo(value);
                            if (item.ActionRequiredName === 'In Progress')
                                setInProgressStatus(true);
                            else
                                setInProgressStatus(false);
                            item.isEditMode = 'true';
                            seteditColumnsDefaultValues(item);
                            setResolutionErrMsg("");
                            setFuctionalSceErrMsg("");
                            setshowEditGridPanel(true);
                        },
                        key: 'edit',
                        text: 'Action',
                        ariaLabel: 'action',
                        iconProps: { iconName: 'Edit', style: { color: "#0078d4" } }
                    }
                ]
            },
            {
                key: 'DataType',
                name: 'Data Type',
                fieldName: 'DataType',
                type: 'string',
                isResizable: true,
                minColumnWidth: 90,
                maxWidth: 90,
                ariaLabel: 'Data Type',
                data: [],
                onRender: (item: any) => {
                    if (item.IsLocked === true) {
                        return <TooltipHost content={item.DataType}>
                            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)', marginLeft: '-14px' }}>
                                <FontIcon iconName="LockSolid" style={{ color: 'rgb(0, 120, 212)', fontWeight: 500 }}></FontIcon>
                                {item.DataType}
                            </span>
                        </TooltipHost>
                    }
                    else {
                        return <TooltipHost content={item.DataType}>
                            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                                {item.DataType}
                            </span>
                        </TooltipHost>
                    }
                },
                iconName: appendSortIcon('DataType'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'DataValue',
                name: 'Data Value',
                fieldName: 'DataValue',
                type: 'link',
                url: 'url',
                isResizable: true,
                minColumnWidth: 110,
                maxWidth: 110,
                ariaLabel: 'Data Value',
                data: [],
                onRender: (item: any) => {
                    return <Link onClick={(event) => { openModal(item) }} >
                        {item.DataValue}
                    </Link>
                },
                iconName: appendSortIcon('DataValue'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'DisplayName',
                name: 'Display Name',
                fieldName: 'DisplayName',
                type: 'string',
                isResizable: true,
                minColumnWidth: 200,
                maxWidth: 200,
                ariaLabel: 'Display Name',
                data: [],
                onRender: (item: any) => {
                    return <TooltipHost content={item.DisplayName}>
                        <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                            {item.DisplayName}</span>
                    </TooltipHost>
                },
                iconName: appendSortIcon('DisplayName'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'AuditIssue',
                name: 'Audit Issue',
                fieldName: 'AuditIssue',
                type: 'string',
                isResizable: true,
                minColumnWidth: 170,
                maxWidth: 170,
                ariaLabel: 'Audit Issue',
                data: [],
                onRender: (item: any) => {
                    return <TooltipHost content={item.AuditIssue}>
                        <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                            {item.AuditIssue}</span>
                    </TooltipHost>
                }
            },
            {
                key: 'AssignedToUserName',
                name: 'Assigned To',
                fieldName: 'AssignedToUserName',
                type: 'persona',
                isResizable: true,
                minColumnWidth: 220,
                maxWidth: 220,
                ariaLabel: 'Assigned To',
                imageUrl: 'imageUrl',
                personaAlt: (item) => `Profile image for ${item.fullName}`,
                data: [],
                iconName: appendSortIcon('AssignedToUserName'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'ActionRequired',
                name: 'Action Required',
                fieldName: 'ActionRequired',
                type: 'number',
                isResizable: true,
                minColumnWidth: 130,
                maxWidth: 130,
                ariaLabel: 'Action Required',
                className: 'cellLeftAligned customGridCell',
                onRender: (item: any) => {
                    switch (item.ActionRequiredName) {
                        case UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key:
                            {
                                return <span>
                                    <FontIcon iconName="Recent" style={{ color: 'rgba(215, 59, 2, 1)', fontWeight: 500 }}></FontIcon>
                                    {' ' + UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key}</span>
                            }
                        case UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key:
                            {
                                return <span>
                                    <FontIcon iconName="Completed" style={{ color: 'green', fontWeight: 500 }}></FontIcon>
                                    {' ' + UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key}</span>
                            }
                        case UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key:
                            {
                                return <span>
                                    <FontIcon iconName="ProgressRingDots" style={{ color: 'green', fontWeight: 500 }}></FontIcon>
                                    {' ' + UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key}</span>
                            }
                    }
                },
                data: [],
                iconName: appendSortIcon('ActionRequired'),
                iconClassName: "customSortIcon"
            }, 
            {
                key: 'CreatedDate',
                name: 'Created Date',
                fieldName: 'CreatedDate',
                type: 'date',
                isResizable: true,
                minColumnWidth: 120,
                maxWidth: 120,
                ariaLabel: 'CreatedDate',
                data: [],
                onRender: (item: any) => {
                    return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left', fontWeight: 500 }}>
                        {dayjs(item.CreatedDate).format('MM/DD/YYYY')}</span>
                },
                iconName: appendSortIcon('CreatedDate'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'ResolvedBy',
                name: 'Resolved By',
                fieldName: 'ResolvedName',
                type: 'persona',
                isResizable: true,
                minColumnWidth: 200,
                maxWidth: 200,
                ariaLabel: 'Resolved By',
                imageUrl: 'imageUrl',
                personaAlt: (item) => `Profile image for ${item.fullName}`,
                data: [],
                iconName: appendSortIcon('ResolvedName'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'Resolution',
                name: 'Resolution',
                fieldName: 'Resolution',
                type: 'string',
                isResizable: true,
                minColumnWidth: 120,
                maxWidth: 120,
                ariaLabel: 'Resolution',
                data: [],
                iconName: appendSortIcon('DataValue'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'TargetCompletionDate',
                name: 'Target Completion Date',
                fieldName: 'TargetCompletionDate',
                type: 'date',
                isResizable: true,
                minColumnWidth: 120,
                maxWidth: 120,
                ariaLabel: 'Target Completion Date',
                data: [],
                onRender: (item: any) => {
                    return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left', fontWeight: 500 }}>
                        {dayjs(item.TargetCompletionDate).format('MM/DD/YYYY')}</span>
                },
                iconName: appendSortIcon('TargetCompletionDate'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'ResolvedDate',
                name: 'Resolved Date',
                fieldName: 'ResolvedDate',
                type: 'string',
                isResizable: true,
                minColumnWidth: 120,
                maxWidth: 120,
                ariaLabel: 'Resolved Date',
                data: [],
                onRender: (item: any) => {
                    return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left', fontWeight: 500 }}>
                        {isEmpty(item.ResolvedDate) ? '' : dayjs(item.ResolvedDate).format('MM/DD/YYYY')}</span>
                },
                iconName: appendSortIcon('ResolvedDate'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'IssueType',
                name: 'Issue Type',
                fieldName: 'IssueType',
                type: 'string',
                isResizable: true,
                minColumnWidth: 95,
                maxWidth: 95,
                ariaLabel: 'Issue Type',
                data: []
            },
            {
                key: 'FunctionalScenario',
                name: 'Functional Scenario',
                fieldName: 'FunctionalScenario',
                type: 'string',
                isResizable: true,
                minColumnWidth: 170,
                maxWidth: 170,
                ariaLabel: 'Functional Scenario',
                data: [],
                onRender: (item: any) => {
                    return <TooltipHost content={item.FunctionalScenario}>
                        <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                            {item.FunctionalScenario}</span>
                    </TooltipHost>
                },
                iconName: appendSortIcon('FunctionalScenario'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'ICM URL',
                name: 'ICM URL',
                fieldName: 'ICMURL',
                type: 'link',
                url: 'url',
                isResizable: true,
                minColumnWidth:150,
                maxWidth: 150,
                ariaLabel: 'ICM URL',
                data: [],
                onRender: (item: any) => { 
                    return <Link aria-label="ICM URL link" href={item.ICMURL} target="_blank">
                     {item.ICMURL}
                   </Link> 
                },
                iconName: appendSortIcon('ICMURL'),
                iconClassName: "customSortIcon"
            }
        ];
        return advancedColumns;
    };
    
    const disableCreateButton = (setICMDetails: { ServiceName: any; Title: any; Team: any; Summary:any; Discussion:any} ): any => { 


        if (isEmpty(setICMDetails?.ServiceName) || isEmpty(setICMDetails?.Title) || isEmpty(setICMDetails?.Team) || isEmpty(setICMDetails?.Summary) || isEmpty(setICMDetails?.Discussion)) {
            return true;
        }
        else {
            return false;
        }
          
  } 
    
    const handleDateSelection = (date: any, _item: any) => {
        const sDate = new Date(date);
        const newEditColumnsDefaultValues = { ...editColumnsDefaultValues };
        let vsDate = dayjs(sDate).startOf("day").toDate();
        const formattedDate = dayjs(vsDate).format("YYYY-MM-DDTHH:mm:ss");
        
        for (let key in newEditColumnsDefaultValues) {
            if (key === "TargetCompletionDate") {
                newEditColumnsDefaultValues[key] = formattedDate;
                break;
            }
        }
        seteditColumnsDefaultValues(newEditColumnsDefaultValues);
    }

    const onChangeText = (e: any, text: any, _item: any) => {
        SetObjValues(_item.key, text.value);
    }


    //AssignedTo - AuditDatamonitoringissues
    const onSelectContact = (contact: any) => {
       
        if(!isEmpty(contact)  && !isEmpty(contact.objectId)){
                setAssignToErr(false);
                setIsDisabledSaveButton(false);
                let editedcontact = [{
                    text: contact.text,
                    objectId: contact.objectId
                }]
                setAssignedTo(editedcontact)
        }
        else{
            setAssignedTo([])
            setAssignToErr(true);
            setIsDisabledSaveButton(true);
        }
    }							  
	const onSelectICMContact = (ICMcontact: any) => {
        if (isEmpty(ICMcontact)) {
            setAssignedTo([])
            setAssignToErr(true);
            setIsDisabledSaveButton(true);
        }
        else {
            setAssignToErr(false);
            setIsDisabledSaveButton(false);
            let editedcontact = [{
                text: ICMcontact.text,
                objectId: ICMcontact.key
            }]
            setAssignedTo(editedcontact)
        }
            
      }				 
   const requestOptions = {
        method: 'Get',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
    };
    const fetchFunctionalScenarioByAffectedDataTypeId = (datatype: any, affecteddatatypeid: any) => {
        if ((FunctionalScenariosList.length > 0 ? FunctionalScenariosList.filter((item: any) => item.DataType === datatype).length : 0) === 0) {
            DOMEApiHelper('FinanceDataAudit/GetAllFunctionalSCenarioById?affecteddatatypeid=' + affecteddatatypeid, requestOptions)
                .then(res => {
                    let functionalScenariosList = res.map((item: any) => {
                        return {
                            DataType: datatype,
                            key: item.Id,
                            text: item.Title
                        }
                    })
                    setFunctionalScenariosList([...FunctionalScenariosList, ...functionalScenariosList]);
                    setFuncScenario(functionalScenariosList.filter((item: any) => item.DataType === datatype))
                }).catch(error => {
                    // setIsLoaded(false); 
                   
                });
        }
        else {
            setFuncScenario(FunctionalScenariosList.filter((item: any) => item.DataType === datatype))
        }


      

    }

    const requestOptionICM = {
        method: 'Get',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
    };
    const getICMServiceDetailsByServiceName = (serviceName:any) => {
        setICMTeamsList([]);
        DOMEApiHelper('ICM/GetDetailsByServiceName?ServiceName=' + serviceName, requestOptionICM)
            .then(res => {
                
                if(!isEmpty(res) && res.Value.length > 0){
                    getICMTeamsByServiceID(res.Value[0].Id);
                 }
                else{
                    setIsLoaded(false);
                    setToastTitle("ICM");
                    setToastMessage("No Service found. Please check the service name and try again.");
                    setToastType(CoherenceNotificationType.ERROR);
                    setShowToast(true);
                    setTimeout(() => {
                        setShowToast(false);
                    }, 5000);
                }
            }).catch(error => {
               
                setToastTitle("ICM Service Search Error");
                setToastMessage(isEmpty(error["message"])?error:error["message"]);
                setToastType(CoherenceNotificationType.ERROR);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                 }, 5000);
            });
    } 

    const getICMTeamsByServiceID = (serviceID:any) => { 
        DOMEApiHelper('ICM/GetTeamsByServiceID?serviceID='+ serviceID, requestOptionICM)
            .then(res => { 
                if(!isEmpty(res) && res.Teams.length > 0){
                    setICMTeamsList(res.Teams.map((item:any) => {
                        return {
                          key: item.Id,
                          text: item.Name
                    }}));        
                }
                else{
                    setICMTeamsList([]);
                    setIsLoaded(false);
                    setToastTitle("ICM");
                    setToastMessage("No ICM Teams found for the given service name. Please check the service name and try again.");
                    setToastType(CoherenceNotificationType.ERROR);
                    setShowToast(true);
                    setTimeout(() => {
                        setShowToast(false);
                    }, 5000);
                }
            }).catch(error => {
                //Catch Error
            });
    } 

    const SetObjValues = (key: string, value: any, _isChanged: boolean = true, _errorMessage: string | null = null): void => {
        seteditColumnsDefaultValues({ ...editColumnsDefaultValues, [key]: value })    
    }

    const onDropDownChange = (_event: React.FormEvent<HTMLDivElement>, selectedDropdownItem: IDropdownOption | undefined, item: any): void => {
        setResolutionErrMsg("");
        setFuctionalSceErrMsg("");
        if (bulkEditButtonClicked === true && bulkEditSelectedData.filter((item: any) => item.DataType === bulkEditSelectedData[0].DataType).length < bulkEditSelectedData.length && selectedDropdownItem?.key === 'Configuration') {
            //Error msg for bulk edit when records with more than 1 datatype is selected
            if (selectedDropdownItem?.key === 'Configuration') {
                SetObjValues(item.key, undefined);
            }

            setToastTitle("Bulk edit Error");
            setToastMessage("Please select the records with same datatype to set resolution as configuration.");
            setToastType(CoherenceNotificationType.ERROR);
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
                SetObjValues(item.key, null);
            }, 5000);

        }
        else if (bulkEditButtonClicked === true && selectedDropdownItem?.key === 'Configuration') {
            //bulk edit for records with same datatype  
            fetchFunctionalScenarioByAffectedDataTypeId(bulkEditSelectedData[0].DataType, bulkEditSelectedData[0].AffectedDataTypeId);
            SetObjValues(item.key, selectedDropdownItem?.key);
        }
        else {
            SetObjValues(item.key, selectedDropdownItem?.key);
        }

    } 

    const requestOptionsGETALL = {
        method: 'GET'
    };



    const LoadData = (text: string) => {
        setIsLoaded(true);
        setLoadText(text);
        setAuditDataMonitoringIssueFilteredData([]);
        resetSelection();
        setActiveTab(NotificationPivotOptions.ACTIONREQUIRED); 
        DOMEApiHelper('FinanceDataAudit?userId=' + sessionStorage.getItem("localAccountId"), requestOptionsGETALL)
            .then(res => {
                if (res !== undefined && res.length > 0 && res[0]["FinanceDataAuditList"].length > 0) {
                    //to add isSelected for all the rows to use for bulk edit checkbox selection
                    res[0]["FinanceDataAuditList"].forEach((item: any, index: any) => {
                        item.isSelected = false;
                        if(isEmpty(item.AssignedToUserName)){
                            item.AssignedToUserName= item.AssignedTo;
                             }
                    });
                    //bydefault show action required data.
                    let auditDataMonitoringIssueActionRequiredData = res[0]["FinanceDataAuditList"].filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key);
                    setAuditDataMonitoringIssueData(auditDataMonitoringIssueActionRequiredData);
                    setAuditDataMonitoringIssueBeforeFilterData(res[0]["FinanceDataAuditList"]); //store data to store before filter  
                    
                    setResolution(res[0]["ResolutionList"].filter((item:any) => item.Title !== 'System Resolved').map((item: any) => {
                        return {
                            key: item.Title,
                            text: item.Title
                        }
                    }));
                    setActionRequired(res[0]["ActionRequiredList"].map((item: any) => {
                        return {
                            key: item.Id,
                            text: item.Title
                        }
                    }));
                    setRefColumnsDefaultValues(editColumnsDefaultValues);

                    //sort bydefault 
                    if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) {
                        sortDefault(auditDataMonitoringIssueActionRequiredData)
                    }
                    else {
                        resetPagination();
                    }
                }
                setIsLoaded(false);
                setLoadText("");

            }).catch(error => {
                setLoadText("");
                setIsLoaded(false);
                setToastTitle("Error while fetching finance data audit data");
                setToastMessage(error.toString());
                setToastType(CoherenceNotificationType.ERROR);
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 5000);
            });
    }

    useEffect(() => {
        setIsLoaded(true);		 
        LoadData("Loading data");
        // eslint-disable-next-line 
    }, [setAuditDataMonitoringIssueData]);

    interface UpdateAuditDataMonitoringIssuesModel {
        Id: number;
        Resolution?: string | null;
        FunctionalScenarioId?: string | null;
        TargetCompletionDate?: string | null;
        ActionRequired?: number | null;
        ResolvedBy?: string | null;
        ModifiedBy?: string | null;
        AssignedTo?: string | null,
        ICMURL?: string | null
    }

    const SaveAPI = (Id: number,icmResponse? : number) => {
        var userID = sessionStorage.getItem("localAccountId");
        const item = auditDataMonitoringIssueData.find((dataItem: any) => dataItem.Id === editColumnsDefaultValues['Id']);
        const OriginalData = item;

        const actionResolved = UtilDta.filter((data: any) => data.id.includes("Resolved"));
        const ActionResolvedKeyId = ActionRequired.filter((data: any) => data.text.includes(actionResolved[0].key));

        const updateModifiedBy = (data: any) => {
            data['ModifiedBy'] = userID;
            return data;
        }
        let inputList: any = [];
        let data: UpdateAuditDataMonitoringIssuesModel = {
            Id: editColumnsDefaultValues['Id'],
            Resolution: null
        };


        if(!isEmpty(icmResponse)){
            let icmURL  = `https://portal.microsofticm.com/imp/v3/incidents/incident/${icmResponse}/summary`;    
            bulkEditSelectedData.forEach((obj: any) => {
                data["Id"] = obj.Id;
                data["ICMURL"] = icmURL;
                data = updateModifiedBy(data);
                inputList.push(JSON.parse(JSON.stringify(data)));
            })   
        }
        else{
                if (!AssignedToEditButtonClicked) {
                    data["Resolution"] = editColumnsDefaultValues['Resolution']
                    
                    if ((editColumnsDefaultValues['Resolution'] != null && bulkEditButtonClicked) || (!bulkEditButtonClicked && editColumnsDefaultValues['Resolution'] !== OriginalData['Resolution'])) {
                        data['ResolvedBy'] = userID;
                        data = updateModifiedBy(data);
                    }
                    if ((bulkEditButtonClicked) || (!bulkEditButtonClicked && editColumnsDefaultValues['TargetCompletionDate'] !== OriginalData['TargetCompletionDate'])) {
                        if (bulkEditButtonClicked && editColumnsDefaultValues['TargetCompletionDate'] === null) {
                            const sDate = new Date();
                            let vsDate = dayjs(sDate).startOf("day").toDate();
                            const formattedDate = dayjs(vsDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
                            data['TargetCompletionDate'] = formattedDate;
                        }
                        else {
                            data['TargetCompletionDate'] = editColumnsDefaultValues['TargetCompletionDate'];
                            data = updateModifiedBy(data);
                        }

                    }

                    if (bulkEditButtonClicked && AssignedTo.length > 0) {
                        bulkEditSelectedData.forEach((obj: any) => {
                            data["Id"] = obj.Id;
                            data["AssignedTo"] = AssignedTo.length > 0 ? AssignedTo[0].objectId : ""
                            data = updateModifiedBy(data);
                        })

                    }
                    else if (AssignedTo.length > 0 && AssignedTo[0].objectId !== "" && AssignedTo[0].objectId !== editColumnsDefaultValues.AssignedTo) {
                        data['AssignedTo'] = AssignedTo[0].objectId
                        data = updateModifiedBy(data);
                    }
                }
            
            
                if (bulkEditButtonClicked) {
                    bulkEditSelectedData.forEach((obj: any) => {
                        data["Id"] = obj.Id;
                        if (data['Resolution'] === 'False Positive') {
                            data["TargetCompletionDate"] = obj.TargetCompletionDate;
                        }
                        inputList.push(JSON.parse(JSON.stringify(data)));
                    })
                }
                else {
                    inputList.push(data);
                }
    }

        if (inputList.length > 0 && Object.keys(inputList[0]).length > 2) {

            //if call the save method via icmcreation then skip showing the loader and toast message
            if(isEmpty(icmResponse)){
                setIsLoaded(true);
                setToastTitle("Finance Data Audit");
                setToastMessage("Record is being updated.");
                setToastType(CoherenceNotificationType.PENDING);
                setShowToast(true);                
            }
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(inputList),
                mode: 'cors'
            }
         
             DOMEApiHelper('FinanceDataAudit', requestOptions)
                 .then(res => {
                     //if both auditDatamonitoringissue and discusion changes then calling disucusion after save call
                     if (!isDisabledDiscussion()) {
                         handleValueChange(false);
                         onSaveDiscussion(false); // API Call for discussions 
                     }
                     setIsLoaded(false);
                     setIsFilterApplied(false);//reset filter    
                     if(!isEmpty(icmResponse)){
                         setToastMessage("ICM created successfully.");
                         setToastType(CoherenceNotificationType.SUCCESS);
                         setShowToast(true);
                         setsshowICMGridPanel(false);
                         setTimeout(() => {
                             setShowToast(false);
                         }, 5000);
                     }else{
                         setToastType(CoherenceNotificationType.SUCCESS);
                         setToastMessage("Record is updated successfully.");
                         setTimeout(() => {
                             setShowToast(false);
                         }, 5000);
                     }                
                     LoadData("Loading data");
                 }).catch(res => {
                     setToastMessage(res.toString());
                     setToastType(CoherenceNotificationType.ERROR);
                     setTimeout(() => {
                         setShowToast(true);
                     }, 5000);
                     setIsLoaded(false);
                 });
        }
        else {
            setToastTitle("Finance Data Audit");
            setToastMessage("No changes have been made");
            setToastType(CoherenceNotificationType.NONE);
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false);
            }, 1000);
        }
    }

    const getPopUpdata = (item: any) => {
        seteditColumnsDefaultValues(item);
        setDataAttributes(JSON.parse(item.DataIssueAttributes));
        setDataMismatchAttributesTitle(item.AuditIssue + " for "+ item.DataType + " " + item.DataValue);

        setmodalOpen(true);
    }

    const [dataAttributes, setDataAttributes] = useState<any>();
    const getDataIssueAttributes = (): any => {
        let tmpRenderObj: any[] = [];
        for (let data in dataAttributes) {
            tmpRenderObj.push(
                <><div style={{ display: "flex" }} >
                    <div style={{ width: "40%", textAlign: "left" }}>
                        <Label>{data}</Label>
                    </div>
                    <div style={{ width: "70%", padding: "5px" }}>: {String(dataAttributes[data])}</div>
                </div>
                </>
            );
        }
        if (!isEmpty(editColumnsDefaultValues) && !isEmpty(editColumnsDefaultValues["MLRecommendation"])) {
            tmpRenderObj.push(
                <><div style={{ display: "flex" }} >
                    <div style={{ width: "40%", textAlign: "left" }}>
                        <Label>ML Recommendation</Label>
                    </div>
                    <div style={{ width: "70%", padding: "5px" }}>: {editColumnsDefaultValues["MLRecommendation"]}</div>
                </div>
                </>
            );
        }
        return tmpRenderObj;
    }

   
    const onChangeICMTitle = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setICMDetails({ ...icmDetails, Title: newValue });
    }; 

    //RichTextField
    const handleRichTextChange = (value: string | undefined): void => {  
        setICMDetails({ ...icmDetails, Summary: value });
    } 

     //RichTextField
     const handleDiscussionRichTextChange = (value: string | undefined): void => {  
        setICMDetails({ ...icmDetails, Discussion: value });
    } 

    const onInputICMServiceName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setICMDetails({ ...icmDetails, ServiceName: newValue }); 
    }; 

    const handleICMSearchClick = () => { 
        getICMServiceDetailsByServiceName(icmDetails.ServiceName);
    };

    const getTextFromItem = (item: ITag) => item.name; 
    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested Result(s)', 
        noResultsFoundText:'No results found'
      }; 
      const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
          return false;
        }
        return tagList.some(compareTag => compareTag.key === tag.key);
      };
      
      //plugin code start 
const getPlugins = () => {
    let plugins: EditorPlugin[] = [];
    plugins.push(
      new CustomRTEPlugin((content) => {
      })
    );
    return plugins;
  };
  //plugin code end 
  
const filterSuggestedTags = async (filterText: string, tagList?: ITag[] | undefined): Promise<ITag[]> => {
    const suggestionsHeaderText = 'Suggested Result(s)';
    let minChar=3;
    let noResultsFoundText='No results found'; 
    if(!isEmpty(filterText)){
      noResultsFoundText = filterText.length >= minChar ? 'No results found' : 'Enter a minimum of '+minChar+' characters for result(s)';
    } 
    // Update pickerSuggestionsProps dynamically
    pickerSuggestionsProps.suggestionsHeaderText = suggestionsHeaderText;
    pickerSuggestionsProps.noResultsFoundText = noResultsFoundText; 
    let result=[]; 
    if(!isEmpty(filterText)&& filterText.length >= minChar){
    result=  icmTeamsList.filter(
        (tag:any) => tag.text.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
      ).map((item:any) => ({ name: item.text })) 
    }
    return result;
  }
    const generateControlsICM = (): any => {

        let tmpRenderObj: any[] = [];
        tmpRenderObj.push(
         <TextField label="Title" 
           placeholder={"Please enter the Title"}
           required
           onChange={onChangeICMTitle} 
          
          />
        );

        tmpRenderObj.push(
           
         <div  style={{ height: '200px',paddingTop:'15px',paddingBottom:'15px'}}>
        <Label>Summary<span style={{paddingLeft:4, paddingBottom:5, color:'#b74c41'}}>*</span></Label>
               <RichTextEditor
                label=""  
                value={icmDetails.Summary}
                onBlur={function noRefCheck() { }}
                onChange={handleRichTextChange}
                onFocus={function noRefCheck() { }}
                onInvalid={function noRefCheck() { }}
                onValid={function noRefCheck() { }}
                placeholder="Please enter the Summary "
                resizable={true}
                plugins={getPlugins()} 
              />
         </div>
        );

        tmpRenderObj.push(
           
            <div  style={{ height: '200px',paddingTop:'15px',paddingBottom:'15px'}}>
           <Label>Discussion<span style={{paddingLeft:4, paddingBottom:5, color:'#b74c41'}}>*</span></Label>
                  <RichTextEditor
                   label=""  
                   value={icmDetails.Discussion}
                   onBlur={function noRefCheck() { }}
                   onChange={handleDiscussionRichTextChange}
                   onFocus={function noRefCheck() { }}
                   onInvalid={function noRefCheck() { }}
                   onValid={function noRefCheck() { }}
                   placeholder="Add your comments"
                   resizable={true}
                   plugins={getPlugins()} 
                 />
            </div>
           );

        tmpRenderObj.push( 
             <div style={{ display: 'flex', alignItems: 'center', paddingTop:'15px' }}>
             <TextField label="Service"
               required
               placeholder="Search by Service Name"
               styles={{ root: { marginRight: '8px' } }}
               onChange={onInputICMServiceName}
             /> 
             <IconButton
              styles={{ root: { marginTop:'30px'} }}
               iconProps={{ iconName: 'Search'}}
               title="Search"
               ariaLabel="Search"
               onClick={handleICMSearchClick}
             />
           </div>
           ); 

           tmpRenderObj.push(
            
            icmTeamsList.length > 0 ?
            <div  style={{ paddingTop:'15px' }}>
                <div>  
                    <Dropdown key="Team"
                        label={"Team"}
                        options={icmTeamsList ?? []} 
                        onChange={(ev, selected) => {
                            setICMDetails({ ...icmDetails, Team: selected?.key });
                        }}
                        placeholder={"Select Team"}
                        required={true} 
                    />                      
                </div>
                </div>: "" 
                );
        return tmpRenderObj;
    }
		
    const generateControls = (): any => {
        let tmpRenderObj: any[] = [];

        let userObj = JSON.parse(JSON.stringify(editColumnsDefaultValues), (_key, value) => {
            return value;
        });
        // eslint-disable-next-line
        for (let editRowColumn of editRowColumns) {
            switch (editRowColumn.type) {
                case 'DropDown':
                    {
                        if (editRowColumn.label === 'Resolution' && editColumnsDefaultValues[editRowColumn.key] !== 'System Resolved') {
                            tmpRenderObj.push(
                                <Dropdown key={editColumnsDefaultValues[editRowColumn.key]}
                                    label={editRowColumn.label}
                                    options={Resolution ?? []}
                                    defaultSelectedKey={editColumnsDefaultValues[editRowColumn.key]}
                                    onChange={(ev, selected) => {
                                        setIsDisabledSaveButton(false);
                                        onDropDownChange(ev, selected, editRowColumn)
                                    }}
                                    placeholder={"Select " + editRowColumn.label} 
                                    defaultValue={editColumnsDefaultValues[editRowColumn.key]}
                                    
                                />
                            );
                        } 
                        else if (editRowColumn.label === 'Resolution' && editColumnsDefaultValues[editRowColumn.key] === 'System Resolved') {
                            tmpRenderObj.push(
                                <Label>{editRowColumn.label}
                                <Label className="readonyLabel" style={{height: '35px'}} disabled>
                                    {editColumnsDefaultValues[editRowColumn.key]} 
                                            </Label>
                                </Label>
                            );
                        }
                    }
                    break;
                    case 'ReadOnly': 
                    // Check if the bulk edit button has not been clicked
                    if (!bulkEditButtonClicked) {
                        // Destructure the label and key from editRowColumn for easier access
                        const { label, key } = editRowColumn;
                    
                        // Retrieve the value associated with the current column key
                        const value = editColumnsDefaultValues[key];
                    
                        // Determine if the current field is for 'Notes' and is empty
                        const isNotesEmpty = label === 'Notes' && key === 'Notes' && isEmpty(value);
                    
                        // Set display label to 'Annotations' if the label is 'Notes', otherwise use the column's label
                        const displayLabel = label === 'Notes' ? 'Annotations' : label;
                    
                        // Apply a specific height style only when dealing with an empty 'Notes' field
                        const heightStyle = isNotesEmpty ? { height: '30px' } : undefined;
                    
                        // Push the Label component into the tmpRenderObj array
                        tmpRenderObj.push(
                            <Label>
                                {/* Display either 'Annotations' or the original label */}
                                {displayLabel}
                                
                                {/* Render the value within a disabled Label, with optional styling for empty 'Notes' */}
                                <Label className="readonyLabel" style={heightStyle} disabled>
                                    {value}
                                </Label>
                            </Label>
                        );
                    }
                break;
                case 'Recommendation':
                    if (!bulkEditButtonClicked && !isEmpty(editColumnsDefaultValues[editRowColumn.key])) {
                        tmpRenderObj.push(
                            <>
                                <Label >{editRowColumn.label}
                                    <Label className="readonyLabel" disabled>{editColumnsDefaultValues[editRowColumn.key]}</Label>
                                </Label>
                            </>
                        );
                    }
                    break;
                case 'Date':                   
                    tmpRenderObj.push(
                        (editColumnsDefaultValues['Resolution'] == UtilDta.filter((data: any) => data.id.includes("InProgress") )[0]?.key ||
                        editColumnsDefaultValues['Resolution'] == UtilDta.filter((data: any) => data.id.includes("Configuration") )[0]?.key ||
                        editColumnsDefaultValues['Resolution'] == UtilDta.filter((data: any) => data.id.includes("ConfigurationInProgress") )[0]?.key
                    )
                      ?
                            <span><Label>{editRowColumn.label}</Label>
                                <DatePicker
                                    value={userObj.TargetCompletionDate ? new Date(userObj.TargetCompletionDate) : new Date()}
                                    minDate={new Date()}
                                    placeholder={"Select " + editRowColumn.label}
                                    onSelectDate={newDate => {
                                        setIsDisabledSaveButton(false);
                                        handleDateSelection(newDate, editRowColumn);
                                    }}
                                    formatDate={onFormatDate}
                                    textField={{ errorMessage: "" }}
                                ></DatePicker></span> : ''
                    );

                    break;
                case 'TextField':
                    tmpRenderObj.push(
                        (editColumnsDefaultValues['Resolution'] === UtilDta.filter((data: any) => data.id.includes("Configuration"))[0].key) ?
                            <TextField label={editRowColumn.label} multiline resizable={false}
                                onChange={(event) => {
                                    setIsDisabledSaveButton(false);
                                    onChangeText(event, event.target, editRowColumn);
                                }}
                            //   defaultValue ={editColumnsDefaultValues["Comments"]}
                            ></TextField>
                            : (editColumnsDefaultValues['Resolution'] === UtilDta.filter((data: any) => data.id.includes("FalsePositive"))[0].key) ?
                                <TextField label={editRowColumn.label} multiline resizable={false}
                                    onChange={(event) => {
                                        setIsDisabledSaveButton(false);
                                        onChangeText(event, event.target, editRowColumn);
                                    }}
                                
                                ></TextField>
                                : ''
                    );
                    break;
                case 'Contact':
                    tmpRenderObj.push(
                        <span>
                            <PrimaryContactPicker EnableGroupSearch={true} label={"Assigned To Group/User"} defaultPrimaryContact={AssignedTo} onSelectPrimaryContact={onSelectContact} onSaveDataValid={onSaveDataValid} required={AssignedToRequired}></PrimaryContactPicker>
                            {/* { assignToErr  && <span  style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: 400, paddingTop: 5}}>Please select Assigned to Group/User</span>} */}
                        </span>
                    );

                    break;
                default:
            }
        }
        return tmpRenderObj;
    }

    const Validate = () => {
        let IsFunctional = false;
        if ((editColumnsDefaultValues["Resolution"] !== '' && editColumnsDefaultValues["Resolution"] !== null && editColumnsDefaultValues["Resolution"] !== "False Positive")) {
            setFuctionalSceErrMsg("Please select functional scenario");
            IsFunctional = true;
        }
        if (IsFunctional) {
            setshowEditGridPanel(true);
            return true;
        }
        else {
            return false;
        }
    }

    const validateAssignedTo = () => {
        if ((!bulkEditButtonClicked && AssignedTo.length === 0)) {
            setAssignToErr(true);
            return false;
        }
        else {
            setAssignToErr(false);
            return true;
        }
    }

    const handleOnPanelClose = () => {
        let assigntostatus = validateAssignedTo()
        if (  assigntostatus && !isDisableDataMonitoring()) {
            handleValueChange(false);
            onEditPanelChange(refColumnsDefaultValues);
        }
        else if (!isDisabledDiscussion()) {//if only disucusion changes 
            handleValueChange(false);
            onSaveDiscussion(true); // API Call for discussions 
        }

    };

    const handleOnPanelXClose = () => {      
        setshowEditGridPanel(false);
        setDisussionData([]);
        setDiscussionRecords([]);
        setsshowICMGridPanel(false);	
        setICMDetails({Title:'',Summary:'',Discussion:'',ServiceName:'',Team:''});		
        setICMTeamsList([]);	 		
    }

    const handleOnPanelJSONXClose = () => {
        setmodalOpen(false);
    }


    //Pagination code start   
    const [dataMonitoringPaginatedData, setDataMonitoringPaginatedData] = useState<any>([]);
    const [refreshPagination, setRefreshPagination] = useState<boolean>(false);
    const updatePaginationData = (data: any) => {
        if (activeTab === NotificationPivotOptions.ALLITEMS) {
            setDataMonitoringPaginatedData(data);
        }
        else if (activeTab === NotificationPivotOptions.ACTIONREQUIRED) {
            setDataMonitoringPaginatedData(data.filter((data: any) =>isEmpty(data.Resolution) && data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
        }
        else if (activeTab === NotificationPivotOptions.ACTIONRESOLVED) {
            setDataMonitoringPaginatedData(data.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key));
        }
        else if (activeTab === NotificationPivotOptions.INPROGRESS) {
            // setDataMonitoringPaginatedData(data.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key));
            setDataMonitoringPaginatedData(data.filter((data: any) => (data.Resolution==='In Progress' || data.Resolution==='Configuration In Progress') && data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key));
        }
        
    }

    const getDataCountByActionType = (inputType: any) => {
        if (inputType === NotificationPivotOptions.ALLITEMS) {
            return isFilterApplied ? auditDataMonitoringIssueFilteredData.length : auditDataMonitoringIssueBeforeFilterData.length;
        }
        else if (inputType === NotificationPivotOptions.ACTIONREQUIRED) {
            return (isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData).filter((data: any) => isEmpty(data.Resolution) && data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key).length
        }
        else if (inputType === NotificationPivotOptions.ACTIONRESOLVED) {
            return (isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Resolved"))[0].key).length
        }
        else if(inputType === NotificationPivotOptions.INPROGRESS){
            return (isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key && (data.Resolution==='In Progress' || data.Resolution==='Configuration In Progress' )).length
        }
    }
    const resetPagination = () => {
        setRefreshPagination(refreshPagination ? false : true);
    }
    //Pagination code end 

    //Pivot related code start
    const [activeTab, setActiveTab] = useState<NotificationPivotOptions>(NotificationPivotOptions.ACTIONREQUIRED);
    const handlePivotChange = (item: any) => {
        resetSelection();
        if (item.props.itemKey === NotificationPivotOptions.ALLITEMS) {
            setAuditDataMonitoringIssueData(isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData);
            if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)
                sortDefault(isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData)  //sort bydefault     
            else resetPagination();
        }
        else if (item.props.itemKey === NotificationPivotOptions.ACTIONREQUIRED) {
            setAuditDataMonitoringIssueData((isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData).filter((data: any) => isEmpty(data.Resolution) && data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
            if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)
                sortDefault((isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData).filter((data: any) => isEmpty(data.Resolution) && data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
            else resetPagination();
        }
        else if (item.props.itemKey === NotificationPivotOptions.ACTIONRESOLVED) {
            setAuditDataMonitoringIssueData((isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === 'Resolved'));
            if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)
                sortDefault((isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === 'Resolved'));
            else resetPagination();
        }
        else if (item.props.itemKey === NotificationPivotOptions.INPROGRESS) {
            setAuditDataMonitoringIssueData((isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key && (data.Resolution==='In Progress' || data.Resolution==='Configuration In Progress')));
            if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)
                sortDefault((isFilterApplied ? auditDataMonitoringIssueFilteredData : auditDataMonitoringIssueBeforeFilterData).filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key && (data.Resolution==='In Progress' || data.Resolution==='Configuration In Progress')));
            else resetPagination();
        }

        setActiveTab(item.props.itemKey);
    };
    //Pivot related code end


    //Filter code start  
    const [auditDataMonitoringIssueBeforeFilterData, setAuditDataMonitoringIssueBeforeFilterData] = useState<any>([]);
    const [auditDataMonitoringIssueFilteredData, setAuditDataMonitoringIssueFilteredData] = useState<any>([]);
    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
    const onApplyFilter = (filteredArray: any) => {
        resetSelection();
        setIsFilterApplied(true);
        setAuditDataMonitoringIssueFilteredData(JSON.parse(JSON.stringify(filteredArray)));
        if (activeTab === NotificationPivotOptions.ALLITEMS) {
            setAuditDataMonitoringIssueData(filteredArray);
        }
        else if (activeTab === NotificationPivotOptions.ACTIONREQUIRED) {
            setAuditDataMonitoringIssueData(filteredArray.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => isEmpty(data.Resolution) && data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
        }
        else if (activeTab === NotificationPivotOptions.ACTIONRESOLVED) {
            setAuditDataMonitoringIssueData(filteredArray.filter((data: any) => data.ActionRequiredName === 'Resolved'));
        }
        else if (activeTab === NotificationPivotOptions.INPROGRESS) {
            setAuditDataMonitoringIssueData(filteredArray.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key && (data.Resolution==='In Progress' || data.Resolution==='Configuration In Progress')));
        }
        resetPagination();
    };

    const onClearFilter = () => {
        setIsFilterApplied(false);
        setAuditDataMonitoringIssueFilteredData([]);
        if (activeTab === NotificationPivotOptions.ALLITEMS) {
            setAuditDataMonitoringIssueData(auditDataMonitoringIssueBeforeFilterData);
        }
        else if (activeTab === NotificationPivotOptions.ACTIONREQUIRED) {
            setAuditDataMonitoringIssueData(auditDataMonitoringIssueBeforeFilterData.filter((data: any) => isEmpty(data.Resolution) && data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key || data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key));
        }
        else if (activeTab === NotificationPivotOptions.ACTIONRESOLVED) {
            setAuditDataMonitoringIssueData(auditDataMonitoringIssueBeforeFilterData.filter((data: any) => data.ActionRequiredName === 'Resolved'));
        }
        else if(activeTab === NotificationPivotOptions.INPROGRESS){
            setAuditDataMonitoringIssueData(auditDataMonitoringIssueBeforeFilterData.filter((data: any) => data.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key && (data.Resolution==='In Progress' || data.Resolution==='Configuration In Progress')));
        }
        resetPagination();
    }

    const createFilterColumns = () => {
        return [
            { "fieldName": "DataType", "label": "Data Type", "type": "dropdown", "options": [] },
            { "fieldName": "DataValue", "label": "Data Value", "type": "searchTextBox", "options": [],"additionalData":{"minChar":2} },
            { "fieldName": "DisplayName", "label": "Display Name", "type": "searchTextBox", "options": [] },
            { "fieldName": "FunctionalScenario", "label": "Functional Scenario", "type": "searchDropdown", "options": [] },
            { "fieldName": "AuditIssue", "label": "Monitoring Issue", "type": "searchDropdown", "options": [] },
            { "fieldName": "AssignedToUserName", "label": "Assigned To", "type": "searchDropdown", "options": [], "Default": sessionStorage.getItem("localAccountName") },
            { "fieldName": "ResolvedName", "label": "Resolved By", "type": "searchDropdown", "options": [] },
            { "fieldName": "TargetCompletionDate", "label": "Target Completion Date", "type": "date", "fromTargetCompletionDate": null, "toTargetCompletionDate": null },
            { "fieldName": "CreatedDate", "label": "Created Date", "type": "date", "fromCreatedDate": null, "toCreatedDate": null },
            { "fieldName": "ResolvedDate", "label": "Resolved Date", "type": "date", "fromResolvedDate": null, "toResolvedDate": null },
            { "fieldName": "Resolution", "label": "Resolution", "type": "multiselectDropdown", "options": [{ key: 'Configuration', text: 'Configuration' }, { key: 'False Positive', text: 'False Positive' }] }
        ];
    }
    //Filter code end




    //Sort code start
    const [sortColumnDetails, setSortColumnDetails] = useState<any>();

    const createSortColumns = () => {
        return [
            { "fieldName": "DataType", "label": "Data Type" },
            { "fieldName": "DataValue", "label": "Data Value" },
            { "fieldName": "DisplayName", "label": "Display Name" },
            { "fieldName": "FunctionalScenario", "label": "Functional Scenario" },
            { "fieldName": "ResolvedName", "label": "Resolved By" },
            { "fieldName": "Resolution", "label": "Resolution" },
            { "fieldName": "TargetCompletionDate", "label": "Target Completion Date" },
            { "fieldName": "CreatedDate", "label": "Created Date" },
            { "fieldName": "ResolvedDate", "label": "Resolved Date" }
        ]
    };

    const sortDefault = (data: any) => {
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) {
            onSorted(sortColumnDetails["columnName"], sortColumnDetails["sortType"], data);
        }
    }
    const appendSortIcon = (columnName: string): any => {
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] === columnName) {
            return sortColumnDetails["sortType"] === "asc" ? "Ascending" : "Descending";
        }
    }

    const onSorted = (columnName: string, sortType: string, auditDataMonitoringIssue?: any): void => {
        resetSelection();
        setSortColumnDetails({ "columnName": columnName, "sortType": sortType });
        let sortedData = (auditDataMonitoringIssue !== undefined ? auditDataMonitoringIssue : auditDataMonitoringIssueData).sort((a: any, b: any) => {
            if (a[columnName] === null) {
                return sortType === 'asc' ? 1 : -1;
            }
            if (b[columnName] === null) {
                return sortType === 'asc' ? -1 : 1;
            }
            if (a[columnName] > b[columnName]) {
                return sortType === 'asc' ? 1 : -1;
            }
            if (a[columnName] < b[columnName]) {
                return sortType === 'asc' ? -1 : 1;
            }
            return 0;
        });

        setAuditDataMonitoringIssueData(JSON.parse(JSON.stringify(sortedData)));
        resetPagination();
    }

    //Sort code end

    //  bulk edit code start 
    const [bulkEditButtonClicked, setBulkEditButtonClicked] = useState<boolean>(false);
    const [bulkEditSelectedData, setBulkEditSelectedData] = useState<any>([]);
    const [AssignedToEditButtonClicked, setAssignedToEditButtonClicked] = useState<boolean>(false);

    // onChange function to update isSelect state
    const handleSelectionCheckbox = (event: any, item: any) => {
        if (event.target.checked) {
            item.isSelected = true;
            setBulkEditSelectedData([...bulkEditSelectedData, item]);
        } else {
            if (isSelectedAll)
                setIsSelectedAll(false);

            item.isSelected = false;
            setBulkEditSelectedData(
                bulkEditSelectedData.filter((selectedItem: any) => selectedItem.Id !== item.Id)
            );
        }

        const updatedSelectedData = dataMonitoringPaginatedData.map((item: any) => ({
            ...item,
            isSelected: item.isSelected
        }));

        setDataMonitoringPaginatedData([...updatedSelectedData]);

        auditDataMonitoringIssueData.forEach((element: any) => {
            if (element.Id === item.Id) {
                element.isSelected = item.isSelected
            }
        });
        setAuditDataMonitoringIssueData([...auditDataMonitoringIssueData]);
    };

    // onChange function to update select all checkbox
    const handleSelectAllCheckbox = (event: any, item: any) => {
        setIsSelectedAll(event.target.checked);

        //update paginated records
        const updatedSelectedData = dataMonitoringPaginatedData.map((item: any) => ({
            ...item,
            isSelected: (item.Resolution === "System Resolved" || item.IsLocked || item.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key) ? false : event.target.checked,
        }));

        setDataMonitoringPaginatedData([...updatedSelectedData]);

        //update all recrods
        const updatedSelectedData1 = auditDataMonitoringIssueData.map((item: any) => ({
            ...item,
            isSelected: (item.Resolution === "System Resolved" || item.IsLocked || item.ActionRequiredName === UtilDta.filter((data: any) => data.id.includes("InProgress"))[0].key) ? false : event.target.checked,
        }));

        setAuditDataMonitoringIssueData([...updatedSelectedData1]);

        const updateBulkUpdateData = event.target.checked ? updatedSelectedData1.filter((x: any) => x.isSelected === true) : [];
        setBulkEditSelectedData([...updateBulkUpdateData])
    };

    const resetSelection = (): any => {
        setIsSelectedAll(false);
        setBulkEditSelectedData([]);

        auditDataMonitoringIssueData.forEach((element: any) => {
            element.isSelected = false;
        });
        setAuditDataMonitoringIssueData([...auditDataMonitoringIssueData]);
    }

    const onClickCommandBarsButtonsEvents = (source: any) => {
        if (source == 'Bulk Edit') {
            setAssignedTo([]);
            setBulkEditButtonClicked(true);
            setIsDisabledSaveButton(true);
            setAssignToErr(false);
            setAssignedToRequired(false);
            if (bulkEditSelectedData.length > 0) {
                let obj = JSON.parse(JSON.stringify(bulkEditSelectedData[0]));
                Object.keys(obj).forEach(key => {
                    obj[key] = null;
                });
                seteditColumnsDefaultValues(obj);
            }
            setshowEditGridPanel(true);
        } else if (source == 'Create ICM') {
                    
            if (bulkEditSelectedData.length > 0) {
                let obj = JSON.parse(JSON.stringify(bulkEditSelectedData[0]));
                Object.keys(obj).forEach(key => {
                    obj[key] = null;
                });
                seteditColumnsDefaultValues(obj);
            }
            let summary = `
            <table style="border-collapse: collapse; width: 100%;">
                <tr>
                    <th style="border: 1px solid #dddddd; background-color: #f2f2f2; text-align: left; padding: 8px; width: auto;">Data Type</th>
                    <th style="border: 1px solid #dddddd; background-color: #f2f2f2; text-align: left; padding: 8px; width: auto;">Data Value</th>
                    <th style="border: 1px solid #dddddd; background-color: #f2f2f2; text-align: left; padding: 8px; width: auto;">Display Name</th> 
                    <th style="border: 1px solid #dddddd; background-color: #f2f2f2; text-align: left; padding: 8px; width: auto;">Audit Issue</th>
                </tr>
                ${bulkEditSelectedData.map((item:any, index:any) =>
                  `
                    <tr key="${index}">
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px; width: auto;">${item.DataType}</td>
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px; width: auto;">${item.DataValue}</td>
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px; width: auto;">${item.DisplayName}</td>  
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px; width: auto;">${item.AuditIssue}</td>  
                    </tr>
                `).join('')}
            </table>
        `;
        
        setICMDetails({ ...icmDetails, Summary: summary });
        setsshowICMGridPanel(true);
        
        }
        else if (source === 'Selected Item(s)') { 
            exportToCSV(bulkEditSelectedData);
             
          } else if (source === 'All Items') {
            exportToCSV(auditDataMonitoringIssueBeforeFilterData);        
          }
        
    }

    const createICM  = async () => {

        let icmDetailsObj = JSON.parse(JSON.stringify(icmDetails), (_key, value) => {
            return value;
        });

        let teamName = icmTeamsList.filter((data: any) => data.key === icmDetailsObj.Team)[0].text;
        
        let data = {
            Title: icmDetailsObj.Title,
           
            Summary: icmDetailsObj.Summary,

            Environment :process.env.REACT_APP_Evn,

            DiscussionEntry:icmDetailsObj.Discussion,

            OwningTeam: teamName,

            OwningService:icmDetailsObj.ServiceName,
           
          };
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: "cors",
        };
      
        setIsLoaded(true);
        setToastTitle("Finance Data Audit");
        setToastMessage("ICM creation is in progress.");
        setToastType(CoherenceNotificationType.PENDING);
        setShowToast(true);
        DOMEApiHelper('ICM/CreateICM', requestOptions)
            .then(res => {              
                if(res>0){  
                onEditPanelChange(refColumnsDefaultValues,res); // to save the seleced records in save api 
              
                }                
            }).catch(res => {
                setToastMessage(res.toString());
                setToastType(CoherenceNotificationType.ERROR);
                setTimeout(() => {
                    setShowToast(true);
                }, 3000);
            });

    }
    const exportToCSV=(data:any):any =>{
        let exportData:any=[];
        data.forEach((obj:any) => {
          let dataIssueAttributes= JSON.parse(obj.DataIssueAttributes);         
          let createObj={ Id: obj.Id,DataType:obj.DataType,DataValue:obj.DataValue,DisplayName:obj.DisplayName, ActionRequired: obj.ActionRequiredName,
            FunctionalScenario:obj.FunctionalScenario ,IssueType:obj.IssueType, MLRecommendation:obj.MLRecommendation ,AuditIssue:obj.AuditIssue,ModifiedBy:obj.ModifiedBy,
            ResolvedBy:obj.ResolvedBy,ResolvedDate:isEmpty(obj.ResolvedDate)?"":dayjs(obj.ResolvedDate).format('MM/DD/YYYY'),ResolvedName:obj.ResolvedName,TargetCompletionDate:isEmpty(obj.TargetCompletionDate)?"":dayjs(obj.TargetCompletionDate).format('MM/DD/YYYY') };
            exportData.push( Object.assign({}, createObj,dataIssueAttributes));
        });       
        convertToCsv(exportData,"Finance Data Audits");
    }
      
    const generateButtons = (): any => {
        const _items: ICommandBarItemProps[] = [
            {
                key: 'Bulk Edit',
                text: 'Bulk Edit',
                ariaLabel: 'Bulk Edit',
                iconProps: { iconName: 'Edit' },
                onClick: () => {
                    

                },
                disabled: bulkEditSelectedData.length > 1 ? false : true,
            },
            {
               key: 'ICM',
               text: 'Create ICM',
               ariaLabel: 'Create ICM',								
               iconProps: { iconName: 'Ticket' },
               onClick: () => {
                                  
               },
               disabled: bulkEditSelectedData.length > 0 ? false : true		   
            },  
            { 			  
                key: 'Export',
                text: 'Export',
                ariaLabel: 'Export',
                disabled: false,
                iconProps: { iconName: 'Download' },
                onClick: () => {
    								   
                },
                subMenuProps: {
                  items: [
                    {
                      key: 'SelectedItems',
                      text: 'Selected Item(s)',
                      disabled: bulkEditSelectedData.length>0 ? false : true,
                      onClick: () => {}
                    },
                    {
                      key: 'AllItems',
                      text: 'All Items',
                      disabled: false,
                      onClick: () => {}
                    }
                  ],
                }
              }
        ];
        return _items;
    }
    //  bulk edit code end


    //discussion code start

    const [disussionData, setDisussionData] = useState<any>();
    const [discussionRecords, setDiscussionRecords] = useState<any>([]);

    const isDisableDataMonitoring = () => {
        if (!bulkEditButtonClicked && assignToErr) {
            return true;
        }

        return ((!bulkEditButtonClicked && (InProgressStatus || editColumnsDefaultValues.IsLocked)
        ) || (bulkEditButtonClicked && ((AssignedTo.length===0) &&  isEmpty(editColumnsDefaultValues.Resolution)))
            || (!bulkEditButtonClicked &&  isDisabledSaveButton)
            || (!bulkEditButtonClicked && !isDataChange()))? true : false;        
    }
    const isDisabledDiscussion = () => {
        if (assignToErr) {
            return true;
        }
        return (isEmpty(disussionData) || isEmpty(disussionData.Discussion)) ? true : false;
    }


    const onValidDiscussionData = (disussionData: any, discussion: any) => {
        setDisussionData(disussionData);
    }



    const onSaveDiscussion = (showToast: any) => {
        
        const bulkInputList : any = [];
        if (bulkEditButtonClicked) {
            let discussionIds = disussionData.DiscussionId.split(',');
            discussionIds.forEach((element: any) => {
                let discussionDataList = {
                    DiscussionId: parseInt(element),
                    Discussion: disussionData.Discussion,
                    DiscussionType : disussionData.DiscussionType,
                    CreatedBy : disussionData.CreatedBy                
                }
                bulkInputList.push(discussionDataList);
            });
        }
        else{
            bulkInputList.push(disussionData);
        }
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bulkInputList),
            mode: "cors",
        };
        if (showToast) {
            setIsLoaded(true);
            setToastTitle("Finance Data Audit");
            setToastMessage("Record is being updated.");
            setToastType(CoherenceNotificationType.PENDING);
            setShowToast(true);
        }
        DOMEApiHelper('PORDetails/SaveDiscussionsDetails', requestOptions)
            .then(res => {
                if (showToast) {
                    setIsLoaded(false);
                    setIsFilterApplied(false);//reset filter
                    setToastType(CoherenceNotificationType.SUCCESS);
                    setToastMessage("Record is updated successfully.");
                    setTimeout(() => {
                        setShowToast(false);
                    }, 5000);
                    LoadData("Loading data");
                }
            }).catch(res => {
                setToastMessage(res.toString());
                setToastType(CoherenceNotificationType.ERROR);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            });
    }
    //discussion code end

    
        const isDataChange =()=>{
            const item = auditDataMonitoringIssueBeforeFilterData.find((dataItem: any) => dataItem.Id === editColumnsDefaultValues['Id']);
        
            const OriginalData = item;
        
            if(!bulkEditButtonClicked &&  AssignedTo.length>0  && !isEmpty(AssignedTo[0].objectId) ){    
                if(editColumnsDefaultValues['Resolution'] !== OriginalData['Resolution']
                || editColumnsDefaultValues['TargetCompletionDate'] !== OriginalData['TargetCompletionDate']
                || AssignedTo[0].objectId !== OriginalData['AssignedTo']
                )
                    {
                        return true;
                    }
                else{   
                    return false;
                } 
            }
       }

       const bulkDiscussionIds = () => {
        // Method for Bulk selected Discussions Ids make as comma separated and to pass prop to Discussion component
        let selectedValues = '';

        bulkEditSelectedData.forEach((element: any, index: number) => {
            selectedValues += element.Id;
            if (index !== bulkEditSelectedData.length - 1) {
            selectedValues += ',';
            }
        });
        return selectedValues;
       }

    return (
        <>
        <div  style={{ 
    minHeight: '100vh' //this added to adjust footer to screen height
  }}
> 
        <h2>Finance Data Audit</h2>
        <Accordion title="Finance Data Audit" isExpandDefault={true}>


            <CustomGridControls defaultFilterSessionKey="FinanceDataAuditDefaultFilter" onClickAdditionalControls={onClickCommandBarsButtonsEvents} additionalControls={generateButtons()} filterColumns={createFilterColumns()} sortColumns={createSortColumns()} data={auditDataMonitoringIssueBeforeFilterData} onApplyFilter={onApplyFilter} onSorted={onSorted} onClearFilter={onClearFilter} />
            {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
            <Pivot onLinkClick={handlePivotChange} selectedKey={activeTab}>
                <PivotItem
                    headerText={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
                    itemKey={NotificationPivotOptions.ALLITEMS}
                    ariaLabel={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ? <CoherenceDataGrid
                            listConfig={getColumns()}
                            data={dataMonitoringPaginatedData}
                            isScrollable={true}
                            isSortable={false}
                            sortByDefault={false}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            ariaLabel={"Finance Data Audit All Items List"}
                        ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}

                    </div>
                </PivotItem>
                <PivotItem
                    headerText={"Action Required (" + getDataCountByActionType(NotificationPivotOptions.ACTIONREQUIRED) + ")"
                    }
                    itemKey={NotificationPivotOptions.ACTIONREQUIRED}
                    ariaLabel={"Action Required (" + getDataCountByActionType(NotificationPivotOptions.ACTIONREQUIRED) + ")"}
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ? <CoherenceDataGrid listConfig={getColumns()}
                            data={dataMonitoringPaginatedData}
                            isScrollable={true}
                            ariaLabelForSelectAllCheckbox={'select all checkbox'}
                            isSortable={false}
                            sortByDefault={false}
                            selectionPreservedOnEmptyClick={true}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            ariaLabel={"Finance Data Audit Action Required List"}
                        ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
                    </div>
                </PivotItem>
                <PivotItem
                    headerText={"Action In Progress (" + getDataCountByActionType(NotificationPivotOptions.INPROGRESS) + ")"
                    }
                    itemKey={NotificationPivotOptions.INPROGRESS}
                    ariaLabel={"Action In Progress (" + getDataCountByActionType(NotificationPivotOptions.INPROGRESS) + ")"}
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ? <CoherenceDataGrid listConfig={getColumns()}
                            data={dataMonitoringPaginatedData}
                            isScrollable={true}
                            ariaLabelForSelectAllCheckbox={'select all checkbox'}
                            isSortable={false}
                            sortByDefault={false}
                            selectionPreservedOnEmptyClick={true}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            ariaLabel={"Finance Data Audit Action In Progress List"}
                        ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
                    </div>
                </PivotItem>
                <PivotItem
                    headerText={"Action Resolved (" + getDataCountByActionType(NotificationPivotOptions.ACTIONRESOLVED) + ")"}
                    itemKey={NotificationPivotOptions.ACTIONRESOLVED}
                    ariaLabel={"Action Resolved (" + getDataCountByActionType(NotificationPivotOptions.ACTIONRESOLVED) + ")"}
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ?
                            <CoherenceDataGrid
                                listConfig={getColumns()}
                                data={dataMonitoringPaginatedData}
                                isScrollable={true}
                                ariaLabelForSelectAllCheckbox={'select all checkbox'}
                                isSortable={false}
                                sortByDefault={false}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                ariaLabel={"Finance Data Audit Action Resolved List"}
                            ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
                    </div>
                </PivotItem>
            </Pivot>
            <CustomPagination refreshPagination={refreshPagination} updatePaginationData={updatePaginationData} data={auditDataMonitoringIssueData} pageSize={8}></CustomPagination>
            <CoherencePanel
                titleText={bulkEditButtonClicked ? "Bulk Edit for " + bulkEditSelectedData.length + " Selected Rows" : "Edit Row Details"}
                isOpen={showEditGridPanel}
                onDismiss={handleOnPanelXClose}
                panelSize={CoherencePanelSize.medium}
                closeButtonAriaLabel="Close"
                onRenderFooter={() => (
                    <Stack horizontal >
                        <PrimaryButton text="Save"
                            disabled={isDisableDataMonitoring() && isDisabledDiscussion()}
                            onClick={handleOnPanelClose} />
                        <DefaultButton text="Cancel" onClick={handleOnPanelXClose} />
                    </Stack>
                )}
            >
                <>
                    <div style={ { display: "grid", gridTemplateColumns: "50% 50%", gridGap: "1rem" }} >
                        {showEditGridPanel && generateControls()}
                    </div>
                    {
                        <NonManagedDiscussions discussionId={bulkEditButtonClicked ? bulkDiscussionIds():editColumnsDefaultValues !== undefined ? editColumnsDefaultValues.Id : 0} discussionType="FinanceDataAudit" onValidDiscussionData={onValidDiscussionData} isExpand={false} isBulkSelected={bulkEditButtonClicked}></NonManagedDiscussions>
                    }
                </>
            </CoherencePanel>
            <CoherencePanel
                title={DataMismatchAttributesTitle}
                titleText={DataMismatchAttributesTitle}
                isOpen={modalOpen}
                onDismiss={handleOnPanelJSONXClose}
                panelSize={CoherencePanelSize.medium}
            >
                {modalOpen && getDataIssueAttributes()}
            </CoherencePanel>
           <CoherencePanel
            titleText={icmopen ? "ICM Alert" : "Create ICM "}
            isOpen={showICMGridPanel}
            onDismiss={handleOnPanelXClose}
            panelSize={CoherencePanelSize.large}
            closeButtonAriaLabel="Close"
            onRenderFooter={() => (
                <Stack horizontal >
                    <PrimaryButton disabled={disableCreateButton(icmDetails)} onClick={createICM} text="Create"
                        />
                    <DefaultButton text="Cancel" onClick={handleOnPanelXClose} />
                </Stack>
            )}
            >
            <>
            {showICMGridPanel && generateControlsICM()}
            </>
           </CoherencePanel>			   		
            </Accordion>
            </div>
        </>
    );
};

export default FinanceDataAudit;
