import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType } from "@coherence-design-system/controls";
import { CommandBar, DetailsListLayoutMode, FontIcon, ICommandBarItemProps, Link, Pivot, PivotItem, TooltipHost } from "@fluentui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { DOMEApiHelper, isEmpty } from "../../../utils/DOMEApiHelper";
import { GetUserRole } from "../../../utils/GetUserRole" 
import ToastMessage from "../../../shared/ToastMessage/ToastMessage";
import UtilDta from "../../../utils/UtilCommon.json";
import { NotificationPivotOptions } from "../../../shared/AppHeader/AppHeader.utils";
import CustomPagination from "../../../shared/CutomPagination/CustomPagination";
import CustomGridControls from "../../../shared/CustomGridControls/CustomGridControls"; 
import Accordion from "../../../shared/Accordion/Accordion";
import { useNavigate } from "react-router-dom";

const MyItems: React.FunctionComponent = () => {
  const [userRole, setUserRole] = useState(GetUserRole());
  const [isLoaded, setIsLoaded] = useState(false);
  const [LoadText, setLoadText] = useState<string>("");
  const [porContactTypeItemsData, setPORContactTypeItemsData] = useState<any>([]);
  const [catalogCategory, setCatalogCategory] = useState<any>([]);
  const [porState, setPORState] = useState<any>([]);

  //toaster code Start
  const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastTitle, setToastTitle] = useState<string>("");
  const [showToast, setShowToast] = React.useState<boolean>(false);
  //toaster code end

  let navigate = useNavigate();
  const getColumns = (): CoherenceDataGridColumn<any>[] => {
    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>, item: any) => {
      const Id = item.PORID;
      const url = `/PORDetails/${Id}`;
      if (event.ctrlKey || event.metaKey) {
        window.open(url, '_blank');
      } else {
        navigate(url);
      }
    };
    var advancedColumns: CoherenceDataGridColumn<any>[] = [

      {
        key: 'PORID',
        name: 'POR ID',
        fieldName: 'PORID',
        type: 'link',
        url: 'url',
        isResizable: true,
        minColumnWidth: 70,
        maxWidth: 70,
        ariaLabel: 'Data Value',
        data: [],
        onRender: (item: any) => {
           return (
            <Link onClick={(event) => handleLinkClick(event as React.MouseEvent<HTMLAnchorElement>, item)}>
              {item.PORID}
            </Link>
          );
        },
        iconName: appendSortIcon('PORID'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'POR Contact Types',
        name: 'POR Contact Types',
        fieldName: 'PORContactTypes',
        type: 'string',
        isResizable: true,
        minColumnWidth: 180,
        maxWidth: 180,
        ariaLabel: 'POR Contact Types',
        data: [], 
        onRender: (item: any) => {
          return <TooltipHost content={item.PORContactTypes}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
            {!isEmpty(item.PORContactTypes)?item.PORContactTypes:'--'}</span>
          </TooltipHost>
        },
        iconName: appendSortIcon('PORContactTypes'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'Title',
        name: 'Title',
        fieldName: 'Title',
        type: 'string',
        isResizable: true,
        minColumnWidth: 260,
        maxWidth: 260,
        ariaLabel: 'Title',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.Title}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.Title}</span>
          </TooltipHost>
        },
        iconName: appendSortIcon('Title'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'PORStateName',
        name: 'POR State',
        fieldName: 'PORStateName',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 120,
        ariaLabel: 'State',
        className: 'cellLeftAligned customGridCell',
        onRender: (item: any) => {
          return <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                <FontIcon iconName="RadioBullet" style={{ color: isEmpty(item.BulletColorUI)? 'black' : item.BulletColorUI, fontWeight: 500 }}></FontIcon>
              {item.PORStateName}</span>
        },
        data: [],
        iconName: appendSortIcon('PORStateName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'PrimaryContact',
        name: 'Primary Contact',
        fieldName: 'PrimaryContact',
        type: 'persona',
        isResizable: true,
        minColumnWidth: 220,
        maxWidth: 220,
        ariaLabel: 'Primary Contact',
        imageUrl: 'imageUrl',
        personaAlt: (item) => `Profile image for ${item.fullName}`,
        data: [],
        iconName: appendSortIcon('PrimaryContact'),
        iconClassName: "customSortIcon"
      },
      
      {
        key: 'ServiceLineData',
        name: 'Service Line',
        fieldName: 'ServiceLineData',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 120,
        ariaLabel: 'Service Line',
        data: [],
        iconName: appendSortIcon('ServiceLineData'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'CatalogCategoryName',
        name: 'Category',
        fieldName: 'CatalogCategoryName',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 120,
        ariaLabel: 'Category',
        data: [],
        iconName: appendSortIcon('CatalogCategoryName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'TargetCompletionDate',
        name: 'Target Completion Date',
        fieldName: 'TargetCompletionDate',
        type: 'date',
        isResizable: true,
        minColumnWidth: 180,
        maxWidth: 180,
        ariaLabel: 'Target Completion Date',
        data: [],
        onRender: (item: any) => {
          return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left', fontWeight: 500 }}>
            {dayjs(item.TargetCompletionDate).format('MM/DD/YYYY')}</span>
        },
        iconName: appendSortIcon('TargetCompletionDate'),
        iconClassName: "customSortIcon"
      },
    ];  
    return advancedColumns;
  };

  const requestOptionsGETALL = {
    method: 'GET'
  };

  const LoadData = (text: string) => {
    setIsLoaded(true);
    setLoadText(text);
    setPORContactTypeItemsFilteredData([]);
    setActiveTab(NotificationPivotOptions.ALLITEMS);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
    DOMEApiHelper('PORItems/GetPORListContactsByUserId?userId='+sessionStorage.getItem("localAccountId"), requestOptionsGETALL)
      .then(res => {
        if (res !== undefined && res.length > 0 ) {
          let porContactTypeItemsList =res.map((item: any) => {
            return {
              PORID: item.PORID,
              Title: item.Title,
              PORStateName: item.PORStateName,
              PrimaryContact: item.PrimaryContact,
              CatalogCategoryName: item.CatalogCategoryName,
              TargetCompletionDate: item.TargetCompletionDate,
              ServiceLineData: item.ServiceLineData,
          
              AssignedToUserName: item.AssignedToUserName,
              AssignedTo: item.AssignedTo,
              PORContactTypes: item.ContactTypes,
              BulletColorUI: item.BulletColorUI,
              IsConfidential: isEmpty(item.IsConfidential) ? false : item.IsConfidential

            }});

          
          porContactTypeItemsList.forEach((item: any, index: any) => {
            item.Id = item.PORID;
            if(isEmpty(item.AssignedToUserName)){
              item.AssignedToUserName= item.AssignedTo;
            }

           
            item.Title = item.IsConfidential ? `Confidential - ${item.PORID}` : item.Title;
           
          });
         
          setPORContactTypeItemsData(porContactTypeItemsList);
          setPORContactTypeItemsBeforeFilterData(porContactTypeItemsList);

          //sort bydefault 
          if(sortColumnDetails !== undefined && sortColumnDetails.length > 0){
            sortDefault(res);
          }
          else {
            resetPagination();
          }
        }
        setIsLoaded(false);
        setLoadText("");

      }).catch(error => {
        setLoadText("");
        setIsLoaded(false);
        setToastTitle("Error while fetching POR Items data");
        setToastMessage(error.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
      });
  }

  useEffect(() => {
    setIsLoaded(true);
    LoadData("Loading data");
    // eslint-disable-next-line 
  }, [setPORContactTypeItemsData]);
  //Pagination code start   
  const [porContactTypeItemsPaginatedData, setPORContactTypeItemsPaginatedData] = useState<any>([]);
  const [refreshPagination, setRefreshPagination] = useState<boolean>(false);
  const updatePaginationData = (data: any) => {
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
      setPORContactTypeItemsPaginatedData(data);
    }
  }

  const getDataCountByActionType = (inputType: any) => {
    if (inputType === NotificationPivotOptions.ALLITEMS) {
      return isFilterApplied ? porContactTypeItemsFilteredData.length : porContactTypeItemsBeforeFilterData.length;
    }
  }
  const resetPagination = () => {
    setRefreshPagination(refreshPagination ? false : true);
  }
  //Pagination code end 

  //Pivot related code start
  const [activeTab, setActiveTab] = useState<NotificationPivotOptions>(NotificationPivotOptions.ALLITEMS);
  const handlePivotChange = (item: any) => {
    if (item.props.itemKey === NotificationPivotOptions.ALLITEMS) {
      setPORContactTypeItemsData(isFilterApplied ? porContactTypeItemsFilteredData : porContactTypeItemsBeforeFilterData);
      if (sortColumnDetails !== undefined && sortColumnDetails.length > 0)
        sortDefault(isFilterApplied ? porContactTypeItemsFilteredData : porContactTypeItemsBeforeFilterData)  //sort bydefault     
      else resetPagination();
    }
    setActiveTab(item.props.itemKey);
  };
  //Pivot related code end


  //Filter code start  
  const [porContactTypeItemsBeforeFilterData, setPORContactTypeItemsBeforeFilterData] = useState<any>([]);
  const [porContactTypeItemsFilteredData, setPORContactTypeItemsFilteredData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const onApplyFilter = (filteredArray: any) => {
    setIsFilterApplied(true);
    setPORContactTypeItemsFilteredData(JSON.parse(JSON.stringify(filteredArray)));
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
      setPORContactTypeItemsData(filteredArray);
    }
    sortDefault(filteredArray); //sort bydefault
    resetPagination();
  };

  const onClearFilter = () => {
    setIsFilterApplied(false);
    setPORContactTypeItemsFilteredData([]);
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
      setPORContactTypeItemsData(porContactTypeItemsBeforeFilterData);
    }
    resetPagination();
  }

  const createFilterColumns = () => {
    return [
      { "fieldName": "PORID", "label": "PORID", "type": "searchTextBox", "options": [],"additionalData":{"minChar":2} },
      { "fieldName": "Title", "label": "Title", "type": "searchTextBox", "options": [],"additionalData":{"minChar":2} },
      { "fieldName": "PORStateName", "label": "POR State", "type": "multiselectDropdown", "options": [],"Default":[] },
      { "fieldName": "PrimaryContact", "label": "Primary Contact", "type": "multiselectDropdown", "options": [] ,"Default":[]},
      { "fieldName": "CatalogCategoryName", "label": "Catalog Category", "type": "multiselectDropdown", "options": [] },
      { "fieldName": "TargetCompletionDate", "label": "Target Completion Date", "type": "date", "fromTargetCompletionDate": null, "toTargetCompletionDate": null },
      { "fieldName": "ServiceLineData", "label": "Service Line","type": "multiselectDropdown", "options": [] },
      { "fieldName": "PORContactTypes", "label": "PORContactTypes",  "type": "contains", "options": [] },
  
    ];
  }
  //Filter code end




  //Sort code start
  
  const [sortColumnDetails, setSortColumnDetails] = useState<any[]>([
    
    //In this Order,  First TargetCompletionDate will sort first 
    { columnName: "TargetCompletionDate", sortType: "asc" }, // sort
   
  ]);
  
  const createSortColumns = () => {
    return [
      { "fieldName": "PORID", "label": "PORID" },
      { "fieldName": "Title", "label": "Title" },
      { "fieldName": "PORStateName", "label": "POR State" },
      { "fieldName": "PrimaryContact", "label": "Primary Contact" },
      { "fieldName": "CatalogCategoryName", "label": "Catalog Category" },
      { "fieldName": "TargetCompletionDate", "label": "Target Completion Date" },
      { "fieldName": "ServiceLineData", "label": "Service Line" },
      { "fieldName": "PORContactTypes", "label": "POR Contact Types" },
     
    ]
  };
  const sortDefault = (data: any) => {    
    if (sortColumnDetails.length > 0) {
      sortColumnDetails.forEach((sortColumn: any) => {
        const { columnName, sortType } = sortColumn;
        onSorted(columnName, sortType, data,true);
      });
    }
  }
    const appendSortIcon = (columnName: string): any => {
        if (sortColumnDetails.length > 0) {
            const sortColumn = sortColumnDetails.find((sortColumn: any) => sortColumn.columnName === columnName);
            if (sortColumn) {
                return sortColumn.sortType === "asc" ? "Ascending" : "Descending";
            }
        }
    }
  const onSorted = (columnName: string, sortType: string, porContactTypeItems?: any,defaultSort = false): void => { 
    if (defaultSort) {   
    setSortColumnDetails((prevSortColumnDetails) => {
      const updatedSortColumnDetails = [...prevSortColumnDetails];
      const existingSortColumn = updatedSortColumnDetails.find((sortColumn: any) => sortColumn.columnName === columnName);
      if (existingSortColumn) {
        existingSortColumn.sortType = sortType;
      } else {
        updatedSortColumnDetails.push({ columnName, sortType });
      }
      return updatedSortColumnDetails;
    });
  } else {
    setSortColumnDetails([{ columnName, sortType }]);
    }

   

    let sortedData = (porContactTypeItems !== undefined ? porContactTypeItems : porContactTypeItemsData).sort((a: any, b: any) => {
      if (a[columnName] > b[columnName]) {
        return sortType === 'asc' ? 1 : -1;
      }
      if (a[columnName] < b[columnName]) {
        return sortType === 'asc' ? -1 : 1;
      }
      return 0;
    });

    setPORContactTypeItemsFilteredData(sortedData);
    setPORContactTypeItemsData(sortedData);
    resetPagination();
  }
 
  //Sort code end

  const onClickCommandBarsButtonsEvents = (source: any) => {
    // empty
    }
  const generateButtons = (): any => {
    // empty
  }

  return (
    <> 
  
      

      <Accordion title="My Items" isExpandDefault={true}>
     
       <CustomGridControls defaultFilterSessionKey="PORCOntactTypesItemsDefaultFilter" onClickAdditionalControls={onClickCommandBarsButtonsEvents} additionalControls={generateButtons()}  filterColumns={createFilterColumns()} sortColumns={createSortColumns()} data={porContactTypeItemsBeforeFilterData} onApplyFilter={onApplyFilter} onSorted={onSorted} onClearFilter={onClearFilter} />
       
        {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
        <Pivot onLinkClick={handlePivotChange} selectedKey={activeTab}>
          <PivotItem
            headerText={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
            itemKey={NotificationPivotOptions.ALLITEMS}
                      ariaLabel={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
          >
            <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
              {!isLoaded ? <CoherenceDataGrid
                listConfig={getColumns()}
                data={porContactTypeItemsPaginatedData}
                isScrollable={true}
                isSortable={false}
                sortByDefault={false}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                ariaLabel={"My All Items List"}
              ></CoherenceDataGrid>
                : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
            </div>
          </PivotItem>
        </Pivot>
        <CustomPagination refreshPagination={refreshPagination} updatePaginationData={updatePaginationData} data={porContactTypeItemsData} pageSize={8}></CustomPagination>
      </Accordion>
      
                
     
    </>
  );
};

export default MyItems; 