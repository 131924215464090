
import { DetailsListLayoutMode, PrimaryButton,  ICommandBarItemProps, CommandBar, Label, Dropdown, IDropdownOption, TextField, Link, Checkbox } from "@fluentui/react";
import React, { useState, useEffect, ChangeEvent } from "react";
import { useParams } from "react-router"; 
import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { GetUserRole } from "../../../../../utils/GetUserRole";
import { DOMEApiHelper, isEmpty } from "../../../../../utils/DOMEApiHelper";
import {AttachmentStyles} from "../Attachments/Attachments.styles";
import ToastMessage from "../../../../../shared/ToastMessage/ToastMessage";
import { useNavigate } from "react-router-dom";

interface prop {
  onSaveAttachments: any;
  attachmentDescriptionList:any;
  ServiceLineError:boolean;
  Reset: boolean;
}
export const Attachments = (props: prop) => {
  const [isLoaded, setIsLoaded] = useState(false);  
  const [userRole, setUserRole] = useState(GetUserRole());
  const [showEditGridPanel, setshowEditGridPanel] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
 const [allFileRecords,setAllFileRecords] = useState<{ file: File;fileid:string;itemSelected:boolean; name: string;fileBlobName:string;fileVersion:string; description: string }[]>([]); 
  const [fileRecords, setFileRecords] = useState<{ file: File;fileid:string;itemSelected:boolean; name: string;fileBlobName:string;fileVersion:string; description: string }[]>([]); 
  const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
  const [selectedDescription, setSelectedDescription] = useState<string>(''); 
  const [otherDescription, setOtherDescription] = useState('');
  const [errorMessage, setErrorMessage] = React.useState<boolean>(false);
                
  const [FileSelected, setFileSelected] = useState(false);  
  const [otherDescriptionErrorMessage,setOtherDescriptionErrorMessage ] = React.useState<boolean>(false);               
  const [onSaveAttachment,setOnSaveAttachment ] = React.useState<boolean>(false);    
  const [showFileNameUnique,setShowFileNameUnique ] = React.useState<boolean>(false);
  const [showValidFileLength,setShowValidFileLength ] = React.useState<boolean>(false);
  const [pORId,setPORId]= useState<any>();       
  const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastTitle, setToastTitle] = useState<string>("");
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const [bulkEditSelectedData, setBulkEditSelectedData] = useState<any>([]);
  const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
   
                                         
  let navigate = useNavigate();
      
  const checkNameOfTheFile = (newFileName:any) => {
    const counter = fileRecords.filter(f => f.name === newFileName).length;
    if (counter >= 1) {
     setShowFileNameUnique(true);
     return false;
    }
    if (counter === 0) {
      return true;
    }
   return false;
  }
  const checkLengthOfTheFileName = (newFileName:any) => {
    //File Name must be less than 1000  if greater showing error
    if(newFileName.length>1000){
      setShowValidFileLength(true);
     return false;
    }
    return true;
  }
  const onChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
    setOtherDescriptionErrorMessage(false); //  to reset
    setOnSaveAttachment(false);// to reset 
    setSelectedItem(item);
    setSelectedDescription(item ? item.text : ''); 
    if (item?.text === 'Other'){
      setOtherDescriptionErrorMessage(true);
      
    }
  };
  const handleOnPanelXClose = () => {
    setshowEditGridPanel(false);
    setSelectedDescription(''); // Clear the selected description
    setSelectedItem(undefined); // Reset the selected dropdown option
    setOtherDescription('');
  }
  const onChangeTextFieldValue = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setOtherDescription(newValue || '');
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowFileNameUnique(false);// to reset
    setShowValidFileLength(false);// to reset
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop();
     // checkNameOfTheFile(file.name);
     checkLengthOfTheFileName(file.name);
     //check the length of the file name is 1000 below
      
      if (fileType === 'exe' || fileType === 'js') {
        setErrorMessage(true);
        setSelectedFile(null);
        setFileSelected(false);
      }
      else{
        setSelectedFile(file); 
        setSelectedDescription(selectedDescription);
        setFileSelected(true);
      }
      
    }
    else{
      setFileSelected(false);
    }
  };
       
  
  const ConvertToBlobName =(selectedFile:any,fileVersion :any) =>{
    const originalFile = selectedFile;
    const originalFilename = originalFile.name;
    // Split the filename to get the name and extension
    const fileParts = originalFilename.split('.');
    const namePart = fileParts.slice(0, -1).join('.');
    const extensionPart = fileParts[fileParts.length - 1];
    // Create the new filename
    const newFilename = `${fileVersion.toString().padStart(3, '0')}_${namePart}.${extensionPart}`;

    // Create a new File object with the new filename
    const newFile = new File([originalFile], newFilename, { type: originalFile.type });
    return newFile;
  }
    
  const handleAddFile = () => {
    setOnSaveAttachment(true);
    if(showFileNameUnique){
      return false;
    }
    if(showValidFileLength){
      return false;
    }
    if(!FileSelected){
      return false;
    }
    if(otherDescriptionErrorMessage && otherDescription.trim() === ''){
      return false;
    }
   
    if (selectedFile) {
      let description = selectedDescription;
      if (selectedItem?.text === 'Other') {
        description = otherDescription;
        
      }
      
     
      //check the fileName is already exists if exists the fetch fileVersion from the existing file
      let fileVersion = 1;
   
      let existingFile :any = [];
       if(isEmpty(pORId) && fileRecords.length>0){
         existingFile = fileRecords.filter(f => f.name === selectedFile.name);
       }
       if(!isEmpty(pORId) && pORId>0 && allFileRecords.length>0){
         existingFile = allFileRecords.filter(f => f.name === selectedFile.name);
      }
  
       //create por item
        if(existingFile.length===0){
          fileVersion = 1;
         
        }
        else{
          //check the max version of the existing file
          fileVersion = Math.max(...existingFile.map((item:any) => parseInt(item.fileVersion))) + 1;    
        }
   

      
        // Create a new File object with the new filename with FileName_Version Format
      const newFile = ConvertToBlobName(selectedFile,fileVersion);





      // Uploaded part

      const newFileRecord = { file: newFile,fileid: 'Empty',itemSelected:false, name: selectedFile.name,fileBlobName:newFile.name,fileVersion:fileVersion.toString(), description: description };
      
      setFileRecords([...fileRecords, newFileRecord]);
    
      //Edit Upload Attachment
      if(pORId>0){
          let uploadattachmentdata = {
            PORId:parseInt(pORId),
            AttachmentName: newFileRecord.name,
            AttachmentDescription:newFileRecord.description,
            CreatedBy: sessionStorage.getItem("localAccountId"),
            FileBlobName: newFileRecord.fileBlobName,
            FileVersion: newFileRecord.fileVersion
            
            };
           
            let editfilerecords = [...fileRecords, newFileRecord];
          
            const formData = new FormData();
          

              // Append the new File object to FormData
                formData.append('files', newFileRecord.file);

             // Appending the Attachment Description in formdata
            formData.append('uploadPORJSON', JSON.stringify(uploadattachmentdata));
           
            const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'multipart/form-data' },
            body: formData,
            mode: 'cors'
            };
            setToastTitle("Attachment Upload");
            setToastMessage("Uploading the attachment is in progress.");
            setToastType(CoherenceNotificationType.PENDING);
            setShowToast(true);
            DOMEApiHelper('PORDetails/SaveAttachment', requestOptions)
              .then(res => {
                setToastType(CoherenceNotificationType.SUCCESS);
                setToastMessage("Attachment has been successfully Uploaded.");
                setTimeout(() => {
                  setShowToast(false);
                }, 3000);
    
                if (res.Message==="Success") {
                  LoadAttachmentsData(pORId)
                    
                }
              }).catch(res => {
                setToastMessage(res.toString());
                setToastType(CoherenceNotificationType.ERROR);
                setTimeout(() => {
                  setShowToast(false);
                }, 3000);
              });
           
      }
      else{
            props.onSaveAttachments([...fileRecords, newFileRecord]);                   
      }
     
      setSelectedFile(null); 
      setSelectedItem(undefined); // Reset the selected dropdown option
      setSelectedDescription(''); // Clear the selected description
      setOtherDescription('');
      setOtherDescriptionErrorMessage(false);// to reset
      setOnSaveAttachment(false);// to reset
      setErrorMessage(false);// to reset
    }
    setshowEditGridPanel(false);
  };
 
         //  All check box
         const handleSelectAllCheckbox= (event:any) => { 
              setIsSelectedAll(event.target.checked); 
              fileRecords.forEach((x:any)=>{
              x.itemSelected = event.target.checked ;
              })
                setFileRecords([...fileRecords]);
                const updateBulkUpdateData = event.target.checked ? fileRecords.filter((x: any) => x.itemSelected === true) : [];
                setBulkEditSelectedData([...updateBulkUpdateData]) 
           };
        // Single Selection Box
        const handleSelectionCheckbox = (event:any,item:any) => {   
          if (event.target.checked) {
          item.itemSelected =true;
          setBulkEditSelectedData([...bulkEditSelectedData, item]);
        
          } else {
            if (isSelectedAll)
              setIsSelectedAll(false);
            item.itemSelected =false;
            setBulkEditSelectedData(
            bulkEditSelectedData.filter((selectedItem: any) => !(selectedItem.name === item.name && selectedItem.fileVersion === item.fileVersion))
            );
        
          }   
          
          fileRecords.forEach((element:any) => {
              if(element.name===item.name && element.fileVersion===item.fileVersion){
                  element.itemSelected=item.itemSelected
              }
          });  
          setFileRecords([...fileRecords]);   
        };
        const resetSelection = (): any => {
        setIsSelectedAll(false);
        setBulkEditSelectedData([]);
        fileRecords.forEach((element: any) => {
            element.itemSelected = false;
        });
        setFileRecords([...fileRecords]);
        }
        
     
 

// Call the viewAttachment function with the attachment object
interface Attachment {
  fullLocation: string;
  name: string; // Add a property for the new filename
}
 //To view the attachment
 const viewAttachment = async  (item: any) => { 
  if (item && item.fullLocation && item.name) {
    try {
    
      // Fetch the file content
      const response = await fetch(item.fullLocation);

      // Check if the request was successful
      if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText} (Status Code: ${response.status})`);
      }

      // Convert the response to a Blob
      const blob = await response.blob();

      // Create a temporary link element
      const a = document.createElement('a');
      const objectURL = URL.createObjectURL(blob);

      // Set the href and download attributes
      a.href = objectURL;
      a.download = item.name;

      // Append the link to the document, click it to trigger the download, and then remove it
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Clean up the object URL
      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  } else {
    console.error('Invalid item, fullLocation, or newFileName');
  }

 };

  
  const getColumns = (): CoherenceDataGridColumn<any>[] => {
    var advancedColumns: CoherenceDataGridColumn<any>[] = [
      {
        key: 'selectAll',
        name: 'Select All',
        fieldName: 'SelectAll',
        type: 'string',
        isResizable: false,
        minColumnWidth: 20,
        maxWidth: 20,
        ariaLabel: 'Select All',
        data: [],
        onRender: (item: any) => {
            return <Checkbox ariaLabel="Select row" onChange={(event) => handleSelectionCheckbox(event, item)}  checked={item.itemSelected} />;
        },
        onRenderHeader: (item: any) => {
            return <Checkbox ariaLabel="Select all rows" styles={{ root: { marginTop: '12px', marginBottom: '4px' } }} onChange={(event) => handleSelectAllCheckbox(event)} checked={isSelectedAll} />;
        },
      },
      {
        key: 'Attachment',
        name: 'Attachment',
        fieldName: 'Attachment',
        type: 'link',
        url: 'url',
        isResizable: true,
        ariaLabel: 'Classification Type',
        minColumnWidth: 150,
        maxWidth: 150,
        data: [],
        iconClassName: "customSortIcon",
        onRender: (item: any) => {
          return <Link onClick={(event) => { viewAttachment(item) }} >
          {item.name}
        </Link>
        }, 
      }, 
      {
        key: 'AttachmentDescription',
        name: 'Attachment Description',
        fieldName: 'AttachmentDescription',
        type: 'string',
        isResizable: true,
        ariaLabel: 'Attachment Description',
        minColumnWidth:300,
        maxWidth: 300,
        data: [],
        onRender: (item) => {
          return <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>{item.description}</span>;
        },
      },
      {
        key: 'FileVersion',
        name: 'Version',
        fieldName: 'FileVersion',
        type: 'string',
        isResizable: true,
        ariaLabel: 'File Version',
        minColumnWidth:120,
        maxWidth: 120,
        data: [],
        onRender: (item) => {
          return <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>{item.fileVersion}</span>;
        },
      }
    ]; 
    return advancedColumns;
  };
  const generateAttachmentButton = (): any => {
    const _items: ICommandBarItemProps[] = [
      {
        key: 'Add',
        text: 'Add',
        iconProps: { iconName: 'Add'},
        onClick: () => {
          setshowEditGridPanel(true);
          setFileSelected(false);
          setOtherDescriptionErrorMessage(false); //  to reset
          setOnSaveAttachment(false);// to reset
          setShowFileNameUnique(false);// to reset
          setShowValidFileLength(false);// to reset
        }
        },
        {
          key: 'ActionsDelete',
          name: 'Delete',
          type: 'actions',
          iconProps: {
            iconName: 'Delete',
            style: { color: "red" }
          },
          isResizable: false,
          menuActions: [],
          minColumnWidth: 55,
          maxWidth: 55,
          data: [],
          onClick: () => {
              const result = window.confirm('Are you sure you want to delete the selected attachment(s)?');
      
              if (result) {
              // Edit POR Delete
              if(pORId>0){
                      let todeleterecords = bulkEditSelectedData.filter((item:any) => item.itemSelected === true); 
                      let filenameList: any = [];
                      let fileidList: any = [];
                      let fileBlobNameList: any = [];
                      todeleterecords.forEach((element: any) => {
                        filenameList.push(JSON.stringify(element.fileid));
                        fileidList.push(JSON.stringify(element.name));
                        const locationParts = element.location.split('/');
                        const fileBlobName = locationParts[locationParts.length - 1];
                        fileBlobNameList.push(fileBlobName);
                      });
                        
                    
                  
                        let attachmentData = todeleterecords.map((item: any, index: number) => {
                          const locationParts = item.location.split('/');
                          const fileBlobName = locationParts[locationParts.length - 1];
                       
                          return {
                            fileName: fileBlobName, // to  have same file name in storage fteching from location
                            fileId: item.fileid.toString()                          
                          }
                        });
                      let deleteeditedData = {
                      PORId:pORId,
                      ModifiedBy : sessionStorage.getItem("localAccountId"),
                      Attachments:attachmentData
                      };
                      
                      const requestUpdateOptions = {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body:  JSON.stringify(deleteeditedData),
                        mode: 'cors'
                    };
                    setToastTitle("Delete Attachment(s)");
                    setToastMessage("Deleting the selected attachment(s)");
                    setToastType(CoherenceNotificationType.PENDING);
                    setShowToast(true);
                    DOMEApiHelper('PORDetails/DeleteAttachment', requestUpdateOptions)
                      .then(res => {
                        setIsSelectedAll(false); 
                        setToastType(CoherenceNotificationType.SUCCESS);
                        setToastMessage("Attachment(s) have been successfully deleted.");
                        setTimeout(() => {
                          setShowToast(false);
                        }, 3000);
                        
                        setBulkEditSelectedData([]); 
                  
                        if (res.Message==="Success") {
                          LoadAttachmentsData(pORId);
                            
                        }
                      }).catch(res => {
                        setToastMessage(res.toString());
                        setToastType(CoherenceNotificationType.ERROR);
                        setTimeout(() => {
                          setShowToast(false);
                        }, 3000);
                      });
              }
              else{
                let removeDeletedFiles =fileRecords.filter((item:any) => item.itemSelected === false);               
                setBulkEditSelectedData([]); 
                setFileRecords(removeDeletedFiles); 
                 }
              } // Alert delete 
            
          },
          disabled: (bulkEditSelectedData.length > 0) ? false : true,
        }
      
      
  ];
    return _items;
  }
  const generateAttachmentsPanel = (): any => {
    let tmpRenderObj: any[] = [];
    tmpRenderObj.push(
      <>
        <div>
          <Label> Attachment Name</Label>
         
          <input
            aria-label="select file button"
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            
          />
          {showFileNameUnique && <span  style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: 400, paddingTop: 5}}>Attachment already exists.</span>}
          {showValidFileLength && <span  style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: 400, paddingTop: 5}}>Attachment Length must be less than 1000.</span>}
          {errorMessage ? <span className={AttachmentStyles.warningMessage} >exe and ts files cannot be uploaded</span> : onSaveAttachment && (!FileSelected) && <span className={AttachmentStyles.warningMessage}>Please select  file.</span>}
        </div>
        <br></br>
        <Dropdown
          label={"Attachment Description"}
          options={props.attachmentDescriptionList===undefined ? []:props.attachmentDescriptionList}
          selectedKey={selectedItem ? selectedItem.key : undefined}
          placeholder="Please select the description"
          defaultValue={[]}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption<any> | undefined,
            index?: number | undefined
          ) => onChange(event, option)
            }
                     
        />
      </>
    );
    tmpRenderObj.push(
      selectedItem?.text === 'Other' ? (
        <>
          <br></br>
          <TextField
            multiline
            required
            errorMessage={onSaveAttachment && otherDescriptionErrorMessage && otherDescription.trim() === '' ? 'This field is required.' : undefined}
            onChange={onChangeTextFieldValue}                                             
            placeholder="Please enter custom text"
            value={otherDescription}
      
          />
        </>
      ) : null
    );
    tmpRenderObj.push(
      <>
        <br></br>
        <PrimaryButton  disabled={disableButton()}
         onClick={handleAddFile}>Add Attachment</PrimaryButton>
         
      </>
    );
    return tmpRenderObj;
  } 
  
  // disable the add button 
  const disableButton = () => {
    if(showEditGridPanel){
      return (selectedFile===null || selectedFile===undefined || selectedDescription===undefined ||  selectedDescription==='' || (selectedItem?.text === 'Other' && otherDescription.trim() === '') || errorMessage || showFileNameUnique || showValidFileLength);
    }
    else{
    return false;
    }
  }  
  
  //when in edit mode get the por id
const { Id } = useParams();
useEffect(() => {
  if (Id) {
    LoadAttachmentsData(Id);
    setPORId(Id);
  }
  
}, [Id]);

useEffect(() => {
  //reset all records on service line change (Reset flag)
  if (props.Reset) {
    setFileRecords([]);
  }
}, [props.Reset]);

  const LoadAttachmentsData = (PORID: string) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }, 
      mode: 'cors'
    };
     setIsLoaded(true);
    DOMEApiHelper('PORDetails/GetPORAttachmentsByID?id='+PORID, requestOptions)
      .then(res => { 
        setIsLoaded(false);
        let originalFileRecords = res.map((item: any) => ({
          file: undefined,
          fileid:item.Id,
          key:item.Id,
          itemSelected:false,
          name: item.AttachmentName,
          description : item.AttachmentDescription,
          location:item.Location,
          fullLocation:item.FullLocation,
          isActive : item.IsActive,
          fileVersion: isEmpty(item.FileVersion) ? 1  : item.FileVersion,

        })) 
        setAllFileRecords(originalFileRecords);
        //to store only Active records -- that display in Grid
        let updatedFileRecords = originalFileRecords.filter((item:any) => item.isActive === true);
        setFileRecords(updatedFileRecords);
      }).catch(res => { 
        setIsLoaded(false);
    });
  } 
  return <div > 
    {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <CommandBar
        items={[]}
        farItems={generateAttachmentButton()}
        ariaLabel="Add Attachment"
        primaryGroupAriaLabel="Add Attachment"
        farItemsGroupAriaLabel="Add Attachment"
        style={{ display: 'flex', justifyContent: 'start' }}
      />
    </div> 
    <div style={{ minHeight: 255, height: 255, maxHeight: 255, overflowX: 'auto' }}> 
                  {!isLoaded ?<CoherenceDataGrid
                          listConfig={getColumns()}
                          data={fileRecords}
                          ariaLabelForSelectAllCheckbox={'select all checkbox'}
                          isScrollable={true}      
                          isSortable={false}
                          sortByDefault={false}
                          layoutMode={DetailsListLayoutMode.fixedColumns}
                          ariaLabel={"FileUpload List"}
                        ></CoherenceDataGrid>
                        : <CoherenceLoading primaryText={""}></CoherenceLoading>} 
                </div> 
             
    <CoherencePanel
      title={"Upload Attachment"}
      titleText={"Upload Attachment"}
      isOpen={showEditGridPanel}
      onDismiss={handleOnPanelXClose}
      panelSize={CoherencePanelSize.small}
      closeButtonAriaLabel="Close"
    >
      {props.ServiceLineError ?  
        <><svg width="16" height="20" viewBox="0 -9 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.0001 1.99805C17.5238 1.99805 22.0016 6.47589 22.0016 11.9996C22.0016 17.5233 17.5238 22.0011 12.0001 22.0011C6.47638 22.0011 1.99854 17.5233 1.99854 11.9996C1.99854 6.47589 6.47638 1.99805 12.0001 1.99805ZM11.9963 10.2486C11.4834 10.249 11.061 10.6353 11.0036 11.1327L10.9969 11.2493L11.0005 16.7509L11.0073 16.8675C11.0654 17.3648 11.4884 17.7506 12.0012 17.7502C12.514 17.7499 12.9364 17.3636 12.9939 16.8662L13.0005 16.7496L12.9969 11.248L12.9901 11.1314C12.932 10.6341 12.5091 10.2483 11.9963 10.2486ZM12.0005 6.49908C11.3093 6.49908 10.749 7.0594 10.749 7.7506C10.749 8.4418 11.3093 9.00212 12.0005 9.00212C12.6917 9.00212 13.2521 8.4418 13.2521 7.7506C13.2521 7.0594 12.6917 6.49908 12.0005 6.49908Z" fill="#212121" />
  </svg> <span style={{color:"#a4262c"}}>A service line selection is required to proceed.</span> </>
 : null}
      {showEditGridPanel && generateAttachmentsPanel()}
    </CoherencePanel>
  </div>
};
export default Attachments;
